import { Container, SvgIcon, Typography, useTheme } from "@mui/material";
import { FC, ReactElement } from "react";
import { ReactComponent as UbicoLogo } from "../assets/logos/ubico.svg";

const UnsupportedMobileView: FC = (): ReactElement => {
  const theme = useTheme();
  return (
    <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "50px" }}>
      <SvgIcon
        component={UbicoLogo}
        sx={{ color: theme.palette.text.primary, height: 150, width: 150 }}
        inheritViewBox
      />
      <Typography variant="h4" gutterBottom>
        We are sorry
      </Typography>
      <Typography variant="body1" paragraph>
        At the current moment, we don't support mobile viewing for our web app.
        Please use your desktop computer to login to your Ubico account.
      </Typography>
    </Container>
  );
};

export default UnsupportedMobileView;
