import { AxiosService, UbicoAPIResponse } from "../api-provider";
import { Workspace } from "../constants/data-types";
import { API_PATH } from "../constants/paths";
import { GENERIC_ERROR_MESSAGE } from "../constants/snackbar-messages";

const updateWorkspace = async (
  workspace_id: number,
  data: Workspace,
): Promise<UbicoAPIResponse<Workspace>> => {
  try {
    const workspace = await AxiosService.patch(
      `${API_PATH}/workspaces/${workspace_id}/`,
      data,
    );
    return { error: false, data: workspace.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getAccountWorkspaces = async (): Promise<
  UbicoAPIResponse<Array<Workspace>>
> => {
  try {
    const workspaces = await AxiosService.get(`${API_PATH}/workspaces/`);
    return { error: false, data: workspaces.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const deleteWorkspace = async (
  workspace_id: number,
): Promise<UbicoAPIResponse<Workspace>> => {
  try {
    const workspaces = await AxiosService.delete(
      `${API_PATH}/workspaces/${workspace_id}/`,
    );
    return { error: false, data: workspaces.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const createWorkspace = async (
  data: Workspace,
): Promise<UbicoAPIResponse<Workspace>> => {
  try {
    const workspace = await AxiosService.post(`${API_PATH}/workspaces/`, data);
    return { error: false, data: workspace.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error ||
        error.response?.data?.detail ||
        error.message ||
        GENERIC_ERROR_MESSAGE,
    };
  }
};

const WorkspaceService = {
  getAccountWorkspaces,
  updateWorkspace,
  deleteWorkspace,
  createWorkspace,
};

export default WorkspaceService;
