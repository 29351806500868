import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FC, ReactElement } from "react";
import TrialEndImage from "../../assets/images/dazzle-software-testing.png";
import { BILLING_ROUTE } from "../../routes/appRoutes";
import UbicoButton from "../custom/buttons/Button";

interface PaymentIssueDialogProps {
  open: boolean;
}

const PaymentIssueDialog: FC<PaymentIssueDialogProps> = (
  props,
): ReactElement => {
  const { open } = props;
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      sx={{
        backdropFilter: "blur(5px)",
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <DialogTitle>Payment issue</DialogTitle>
      <DialogContent>
        <Stack
          spacing={theme.spacing(2)}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box
            component="img"
            src={TrialEndImage}
            sx={{
              maxHeight: 200,
              maxWidth: 200,
              width: "auto",
              height: "auto",
              display: "block",
            }}
          />
          <Typography>
            Oops! Seems like there is some issue with your subscription's
            payment. Your 7 days grace period has ended and you need to provide
            a valid payment method before continue to using Ubico.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <UbicoButton variant="contained" href={BILLING_ROUTE}>
          Update payment method
        </UbicoButton>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentIssueDialog;
