import { AxiosService, UbicoAPIResponse } from "../api-provider";
import { Notification, NotificationPreferences } from "../constants/data-types";
import { API_PATH } from "../constants/paths";
import { GENERIC_ERROR_MESSAGE } from "../constants/snackbar-messages";

const getNotificationPreferences = async (): Promise<
  UbicoAPIResponse<NotificationPreferences>
> => {
  try {
    const notificationPreferences = await AxiosService.get(
      `${API_PATH}/notification-preferences/`,
    );
    return { error: false, data: notificationPreferences.data[0], message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const updateNotificationPreferences = async (
  preferences: NotificationPreferences,
): Promise<UbicoAPIResponse<NotificationPreferences>> => {
  try {
    const notificationPreferences = await AxiosService.patch(
      `${API_PATH}/notification-preferences/${preferences.id}/`,
      preferences,
    );
    return { error: false, data: notificationPreferences.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getNotificationsUnreadCount = async (): Promise<
  UbicoAPIResponse<number>
> => {
  try {
    const notifications = await AxiosService.get(
      `${API_PATH}/notifications/unread-count/`,
    );
    return { error: false, data: notifications.data.count, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getNotifications = async (): Promise<
  UbicoAPIResponse<Array<Notification>>
> => {
  try {
    const notifications = await AxiosService.get(`${API_PATH}/notifications/`);
    return { error: false, data: notifications.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const updateNotification = async (
  notificationId: number,
  is_read: boolean,
): Promise<UbicoAPIResponse<Notification>> => {
  try {
    const notifications = await AxiosService.patch(
      `${API_PATH}/notifications/${notificationId}/`,
      { is_read: is_read },
    );
    return { error: false, data: notifications.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const readAllNotifications = async (): Promise<
  UbicoAPIResponse<Array<Notification>>
> => {
  try {
    const notifications = await AxiosService.patch(
      `${API_PATH}/notifications/mark-all-read/`,
    );
    return { error: false, data: notifications.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const NotificationService = {
  getNotificationPreferences,
  updateNotificationPreferences,
  getNotifications,
  getNotificationsUnreadCount,
  updateNotification,
  readAllNotifications,
};

export default NotificationService;
