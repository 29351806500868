import { LocalOffer } from "@mui/icons-material";
import { Chip, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { FC, ReactElement } from "react";
import { UbicoDiscount } from "../../../constants/data-types";

interface CouponChipProps {
  discount: UbicoDiscount
  endText?: string
}

const CouponChip: FC<CouponChipProps> = (props): ReactElement => {
  const { discount, endText = '' } = props;
  const theme = useTheme()

  const getDiscountText = (discount: UbicoDiscount) => {
    if(discount?.percent_off) {
      return `${discount?.percent_off}%`
    } else if (discount?.amount_off) {
      return `${discount?.amount_off}$`
    } else {
      return null
    }
  }

  const discountText = getDiscountText(discount)
  const discountEnd = discount?.expires ? new Date(discount?.expires * 1000) : null
  const discountEndText = discountEnd ? `Until ${discountEnd}` : ''

  return (
    <Tooltip title={discountEndText}>
      <Chip
        color="primary" size="small"
        label={
          <Stack
            direction={"row"}
            spacing={theme.spacing(0.5)}
            alignItems={"center"}
          >
            <LocalOffer sx={{ fontSize: 12 }} />
            <Typography variant="caption">
              {discountText} off {endText}
            </Typography>
          </Stack>
        }
      />
    </Tooltip>
  );
};

export default CouponChip;
