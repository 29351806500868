import { Stack, useTheme } from "@mui/material";
import { FC, ReactNode } from "react";

interface InvoiceItemComponentProps {
  children: ReactNode;
  pl?: string;
  spacing?: string;
}

const InvoiceItemComponent: FC<InvoiceItemComponentProps> = ({
  children,
  pl = null,
  spacing = null,
}) => {
  const theme = useTheme()

  return (
    <Stack
      height={"100%"}
      p={spacing || theme.spacing(1)}
      pl={pl || theme.spacing(2)}
      pr={theme.spacing(2)}
      direction={"row"}
      justifyContent={"space-between"}
    >
      {children}
    </Stack>
  );
};

export default InvoiceItemComponent
