import { createSlice } from "@reduxjs/toolkit";
import { THEME_MODE } from "../../theme";

type AppState = {
  colorMode: string;
};

const initialState: AppState = {
  colorMode: THEME_MODE.Light,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setColorMode: (state) => {
      let currMode = JSON.parse(localStorage.getItem("theme"));
      currMode = currMode ? currMode : THEME_MODE.Light;
      const newMode =
        currMode === THEME_MODE.Light ? THEME_MODE.Dark : THEME_MODE.Light;
      localStorage.setItem("theme", JSON.stringify(newMode));
      state.colorMode = newMode;
    },
  },
});

export const { setColorMode } = appSlice.actions;

export default appSlice.reducer;
