import * as Sentry from "@sentry/react";
import { loadStripe } from "@stripe/stripe-js";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import Providers from "./Providers";
import "./assets/variables/fonts.scss";
import UbicoAppLoading from "./components/custom/AppLoading";
import {
  REACT_APP_ENV,
  REACT_APP_SENTRY_DSN,
  REACT_APP_STRIPE_PUBLISHABLE_KEY,
  SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
  SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
  SENTRY_SAMPLE_RATE,
  SENTRY_TRACE_SAMPLE_RATE,
} from "./constants";
import { StripePromiseProvider } from "./hooks/useStripePromise";
import "./index.scss";
import { persistor } from "./redux/store";
import reportWebVitals from "./reportWebVitals";

const stripe = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  sampleRate: SENTRY_SAMPLE_RATE, // rate of errors to be sent to Sentry
  tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE, // rate of transactions to be sent to Sentry
  replaysSessionSampleRate: SENTRY_REPLAYS_SESSION_SAMPLE_RATE, // The sample rate for session-long replays
  replaysOnErrorSampleRate: SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE, // The sample rate for sessions that has had an error occur.
  enabled: REACT_APP_ENV === "prod" || REACT_APP_ENV === "staging",
  environment: REACT_APP_ENV,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <Providers>
    <StripePromiseProvider stripePromise={stripe}>
      <PersistGate persistor={persistor} loading={<UbicoAppLoading />}>
        <Router>
          <App />
        </Router>
      </PersistGate>
    </StripePromiseProvider>
  </Providers>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
