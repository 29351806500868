import { CssBaseline, PaletteMode } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React from "react";
import { Provider } from "react-redux";
import getComponentsTheme from "./componentsTheme";
import store from "./redux/store";
import { THEME_MODE, getDesignTokens } from "./theme";

export const ColorModeContext = React.createContext({
  toggleAppColorMode: () => {},
});

const Providers: React.FC<{ children: React.ReactNode }> = ({
  children,
}): React.ReactElement => {
  const stored = localStorage?.getItem("theme");
  const [mode, setMode] = React.useState<PaletteMode>(
    stored ? JSON.parse(stored) : THEME_MODE.Light,
  );

  const colorMode = React.useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleAppColorMode: () => {
        setMode((prevMode: PaletteMode) => {
          const newMode =
            prevMode === THEME_MODE.Light ? THEME_MODE.Dark : THEME_MODE.Light;
          localStorage.setItem("theme", JSON.stringify(newMode));
          return prevMode === THEME_MODE.Light
            ? THEME_MODE.Dark
            : THEME_MODE.Light;
        });
      },
    }),
    [],
  );

  // Update the theme only if the mode changes
  const appTheme = React.useMemo(
    () => createTheme(getDesignTokens(mode), getComponentsTheme(mode)),
    [mode],
  );

  return (
    <Provider store={store}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={appTheme}>
          <CssBaseline enableColorScheme />
          {children}
        </ThemeProvider>
      </ColorModeContext.Provider>
    </Provider>
  );
};

export default Providers;
