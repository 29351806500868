import { CircularProgress, styled } from "@mui/material";
import React from "react";
import ubicoColors from "../../assets/variables/colors.scss";

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.main,
  position: "absolute",
  left: "55%",
  top: "50%",
}));

const UbicoAppLoading: React.FC = () => {
  return <StyledCircularProgress />;
};

export default UbicoAppLoading;

export const HTML_UBICO_APP_LOADING = `
  <div style="
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid ${ubicoColors.primary};
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  ">
  </div>
  <style>
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  </style>
`;
