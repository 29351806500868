import { Stack, useTheme } from "@mui/material";
import { FC, ReactElement } from "react";
import { useSelector } from "react-redux";
import UbicoCard from "../../../../../../../components/custom/Card";
import UbicoLinearProgress from "../../../../../../../components/custom/progress.tsx/UbicoLinearProgress";
import {
  Account,
  UbicoSubscription,
} from "../../../../../../../constants/data-types";
import { RootState } from "../../../../../../../redux/store";

const TrialCreditUsage: FC = (): ReactElement => {
  const theme = useTheme();

  const account: Account = useSelector((state: RootState) => state.account);
  const subscription: UbicoSubscription = useSelector(
    (state: RootState) => state.billing.subscription,
  );

  const freeTrialCredits = account?.plan_limits?.free_trial_credits;
  const trialCreditsUsed = subscription?.trial_credits_used;

  const trialCreditsPercentage =
    Math.min((trialCreditsUsed / freeTrialCredits) * 100, 100) || 0;

  return (
    <UbicoCard cardTitle="Trial credits">
      <Stack spacing={theme.spacing(2)}>
        <UbicoLinearProgress
          sx={{ height: 20, borderRadius: 10 }}
          variant="determinate"
          color={"info"}
          value={trialCreditsPercentage}
          startLabel={"Trial Credits Used"}
          endLabel={`${trialCreditsUsed || 0} / ${freeTrialCredits}`}
          labelsPosition="bottom"
        />
      </Stack>
    </UbicoCard>
  );
};

export default TrialCreditUsage;
