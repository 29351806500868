import { Link, Stack, Typography, useTheme } from "@mui/material";
import { FC, ReactElement } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UbicoButton from "../../../../../../components/custom/buttons/Button";
import { Profile } from "../../../../../../constants/data-types";
import { UBICO_SUPPORT_URL } from "../../../../../../constants/urls";
import { useSubscriptionCancellation } from "../../../../../../hooks/useSubscriptionCancellation";
import { RootState } from "../../../../../../redux/store";
import { HOME_ROUTE } from "../../../../../../routes/appRoutes";

const SubscriptionCancelAcceptedDeal: FC = (): ReactElement => {
  const { setOpen } = useSubscriptionCancellation();

  const navigate = useNavigate();

  const complete = () => {
    setOpen(false);
    navigate(HOME_ROUTE);
  };

  const theme = useTheme();

  const profile: Profile = useSelector((state: RootState) => state.profile);

  return (
    <Stack spacing={theme.spacing(2)}>
      <Typography variant="h6">
        {profile?.user?.first_name}, glad to have you back! 🥳
      </Typography>
      <Typography>
        We thrive to improve and provide a better product by listening to our
        customers. Feel free to reach out to{" "}
        <Link href={UBICO_SUPPORT_URL}>support</Link> for any questions.
      </Typography>
      <UbicoButton
        onClick={complete}
        sx={{ textTransform: "capitalize" }}
        variant="contained"
      >
        Bring me back to my dashboard
      </UbicoButton>
    </Stack>
  );
};

export default SubscriptionCancelAcceptedDeal;
