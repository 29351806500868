import { LoadingButton } from "@mui/lab";
import { Divider, Link, Stack, Typography, useTheme } from "@mui/material";
import { FC, ReactElement } from "react";
import { useAccountBilling } from "../../../../../../../hooks/useAccountBilling";
import {
  BillingSteps,
  SELF_SERVE_BILLING_STEPS,
} from "../../../../constants/account-billing";
import AddNewCard from "../payment/AddNewCard";
import ExistingCard from "../payment/ExistingCard";

const Payment: FC = (): ReactElement => {
  const theme = useTheme();

  const { billing, currentStep, setCurrentStep, selectedNewCard } =
    useAccountBilling();

  const completePayment = () => {
    const nextStep = SELF_SERVE_BILLING_STEPS.find(
      (s) => s.id === BillingSteps.BillingDetails,
    );
    setCurrentStep(nextStep);
  };

  if (currentStep?.id !== BillingSteps.Payment) return <></>;

  return (
    <Stack spacing={theme.spacing(3)}>
      <Stack width={"100%"}>
        {Boolean(billing?.payment_method) && <ExistingCard />}
        <Divider flexItem />
        <AddNewCard />
        {!selectedNewCard && billing?.payment_method && (
          <Stack>
            <Divider flexItem />
            <Stack
              direction={"row"}
              p={theme.spacing(2)}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant="body2">
                Payments are processed securely through{" "}
                <Link href="https://stripe.com/en-ca">Stripe</Link>
              </Typography>
              <LoadingButton variant="contained" onClick={completePayment}>
                Continue
              </LoadingButton>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default Payment;
