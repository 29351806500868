export const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const REACT_APP_UBICO_GOOGLE_CLIENT_ID =
  process.env.REACT_APP_UBICO_GOOGLE_CLIENT_ID;
export const REACT_APP_ENV = process.env.REACT_APP_ENV;
export const REACT_APP_MUI_LICENSE = process.env.REACT_APP_MUI_LICENSE;

// https://docs.sentry.io/platforms/javascript/configuration/options/
// Sentry configuration
export const REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const SENTRY_SAMPLE_RATE = 1;
export const SENTRY_TRACE_SAMPLE_RATE = 0.1;
export const SENTRY_REPLAYS_SESSION_SAMPLE_RATE = 0.1;
export const SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE = 0.1;

// Flatfile configuration
export const REACT_APP_UBICO_LOGO = process.env.REACT_APP_UBICO_LOGO;
export const REACT_APP_FLATFILE_ENV_ID = process.env.REACT_APP_FLATFILE_ENV_ID;
export const REACT_APP_FLATFILE_PUBLISHABLE_KEY =
  process.env.REACT_APP_FLATFILE_PUBLISHABLE_KEY;

// Stripe
export const REACT_APP_STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

// Intercom
export const INTERCOM_APP_ID = "t8smzns3";

export const UBICO_DOMAIN = "@ubico.io";

export const UNLAYER_PROJECT_ID = 242188;
