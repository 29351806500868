import { Box, Stack, styled, Typography, useTheme } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import { FC, ReactElement } from "react";

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 8,
  borderRadius: 10,
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
  },
}));

interface UbicoLinearProgressProps extends LinearProgressProps {
  startLabel?: string;
  endLabel?: string;
  labelsPosition?: "top" | "bottom";
}

const UbicoLinearProgress: FC<UbicoLinearProgressProps> = (
  props,
): ReactElement => {
  const {
    startLabel = "",
    endLabel = "",
    labelsPosition = "top",
    ...otherProps
  } = props;
  const theme = useTheme();

  return (
    <Stack spacing={theme.spacing(1)}>
      {labelsPosition === "top" && (
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant="caption" color={"text.disabled"}>
            {startLabel}
          </Typography>
          <Typography variant="caption" color={"text.disabled"}>
            {endLabel}
          </Typography>
        </Stack>
      )}
      <Box sx={{ width: "100%", mr: 1 }}>
        <BorderLinearProgress variant="determinate" {...otherProps} />
      </Box>
      {labelsPosition === "bottom" && (
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant="caption" color={"text.disabled"}>
            {startLabel}
          </Typography>
          <Typography variant="caption" color={"text.disabled"}>
            {endLabel}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default UbicoLinearProgress;
