import { LocalOffer } from "@mui/icons-material";
import { Link, Stack, Typography } from "@mui/material";
import { ReactElement, ReactNode } from "react";
import { UBICO_SUPPORT_URL } from "../../../../constants/urls";
import SubscriptionCancelAcceptedDeal from "../components/billing/cancel/SubscriptionCancelAcceptedDeal";
import SubscriptionCancelConfirm from "../components/billing/cancel/SubscriptionCancelConfirm";
import SubscriptionCancelDeal from "../components/billing/cancel/SubscriptionCancelDeal";
import SubscriptionCancelFeedback from "../components/billing/cancel/SubscriptionCancelFeedback";
import SubscriptionCancelOverview from "../components/billing/cancel/SubscriptionCancelOverview";
import BillingOverviewDetails from "../components/billing/details/BillingDetails";
import CreditUsage from "../components/billing/details/CreditUsage";
import Invoices from "../components/billing/details/Invoices";
import SubscriptionDetails from "../components/billing/details/SubscriptionDetails";
import { default as ManagedBillingManagementDetails } from "../components/billing/management/managed/BillingDetails";
import { default as ManagedPayment } from "../components/billing/management/managed/Payment";
import { default as ManagedReview } from "../components/billing/management/managed/Review";
import BillingManagementDetails from "../components/billing/management/self-serve/BillingDetails";
import ContractTerms from "../components/billing/management/self-serve/ContractTerms";
import Credits from "../components/billing/management/self-serve/Credits";
import Payment from "../components/billing/management/self-serve/Payment";
import PlanConfiguration from "../components/billing/management/self-serve/PlanConfiguration";
import Review from "../components/billing/management/self-serve/Review";

export const MAX_TRIAL_CREDITS = 250

export enum Currencies {
  USD = 'usd'
}

export enum SubscriptionCancellationFields {
  Feedback = "feedback",
  Comment = "comment",
}

export enum CancellationFlowFeedbacks {
  CustomerService = "customer_service",
  LowQuality = "low_quality",
  MissingFeatures = "missing_features",
  SwitchedService = "switched_service",
  TooComplex = "too_complex",
  TooExpensive = "too_expensive",
  Unused = "unused",
  Other = "other",
}

export const CancellationFlowReasonDescriptions = {
  [CancellationFlowFeedbacks.CustomerService]:
    "Customer service was less than expected",
  [CancellationFlowFeedbacks.LowQuality]: "Quality was less than expected",
  [CancellationFlowFeedbacks.MissingFeatures]: "Some features are missing",
  [CancellationFlowFeedbacks.SwitchedService]:
    "I’m switching to a different service",
  [CancellationFlowFeedbacks.TooComplex]: "Ease of use was less than expected",
  [CancellationFlowFeedbacks.TooExpensive]: "It’s too expensive",
  [CancellationFlowFeedbacks.Unused]: "I don’t use the service enough",
  [CancellationFlowFeedbacks.Other]: "Other reason",
};
export enum CancellationFlowSteps {
  Overview = "overview",
  Deal = "deal",
  AcceptedDeal = "accepted-deal",
  Feedback = "feedback",
  CancellationConfirm = "cancellation-confirm",
}

export type CancellationFlowStepType = {
  id: CancellationFlowSteps;
  title: string;
  titleStartIcon?: ReactNode;
  component: ReactNode;
};

export const CANCELLATION_FLOW_STEPS: CancellationFlowStepType[] = [
  {
    id: CancellationFlowSteps.Overview,
    title: "How Ubico helped you",
    component: <SubscriptionCancelOverview />,
  },
  {
    id: CancellationFlowSteps.Deal,
    title: "Limited-time offer",
    titleStartIcon: <LocalOffer fontSize="small" color="primary" />,
    component: <SubscriptionCancelDeal />,
  },
  {
    id: CancellationFlowSteps.Feedback,
    title: "Why cancelling?",
    component: <SubscriptionCancelFeedback />,
  },
  {
    id: CancellationFlowSteps.AcceptedDeal,
    title: "Great to have you back!",
    component: <SubscriptionCancelAcceptedDeal />,
  },
  {
    id: CancellationFlowSteps.CancellationConfirm,
    title: "Until next time!",
    component: <SubscriptionCancelConfirm />,
  },
];

export enum BillingRecurrences {
  Monthly = "month",
  Yearly = "year",
}

export const BillingRecurrenceLabels = {
  [BillingRecurrences.Monthly]: "Monthly",
  [BillingRecurrences.Yearly]: "Yearly",
};

export const BillingRecurrenceLabelsShort = {
  [BillingRecurrences.Monthly]: "mo",
  [BillingRecurrences.Yearly]: "year",
};

export enum BillingOverviewTabs {
  CreditUsage = "credit-usage",
  SubscriptionDetails = "subscription-details",
  Invoices = "invoices",
  BillingDetails = "billing-details",
}

export enum BillingPaymentIntentStatus {
  Canceled = "canceled",
  RequiredPaymentMethod = "requires_payment_method",
}

export enum BillingSubscriptionStatus {
  Incomplete = "incomplete",
  IncompleteExpired = "incomplete_expired",
  Trialing = "trialing",
  Active = "active",
  PastDue = "past_due",
  Canceled = "canceled",
  Unpaid = "unpaid",
  Paused = "paused",
}

export enum BillingSteps {
  Plan = 1,
  Credits = 2,
  Term = 3,
  Payment = 4,
  BillingDetails = 5,
  Review = 6,
}

export type BillingStepType = {
  id: BillingSteps;
  title: string;
  component: ReactNode;
  order: number;
  canOpen: boolean;
  customSubmit?: boolean;
  submitText?: ReactElement;
};

export type BillingDetailTabsType = {
  id: string;
  title: string;
  component: ReactNode;
  order: number;
};

export enum PlanTypes {
  Starter = "starter",
  Professional = "professional",
  Growth = "growth",
}

export enum ProductTypes {
  Plan = "plan",
  Credit = "credit",
}

export const SELF_SERVE_BILLING_STEPS: BillingStepType[] = [
  {
    id: BillingSteps.Plan,
    title: "Configure Your Plan",
    component: <PlanConfiguration />,
    order: 1,
    canOpen: true,
    submitText: (
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        <Typography variant="body2">Need a custom quote?</Typography>
        <Link href={UBICO_SUPPORT_URL}>
          <Typography variant="body2">Contact sales</Typography>
        </Link>
      </Stack>
    ),
  },
  {
    id: BillingSteps.Credits,
    title: "Monthly Credits",
    component: <Credits />,
    order: 2,
    canOpen: true,
  },
  {
    id: BillingSteps.Term,
    title: "Subscription Terms",
    component: <ContractTerms />,
    order: 3,
    canOpen: true,
  },
  {
    id: BillingSteps.Payment,
    title: "Payment",
    component: <Payment />,
    order: 4,
    canOpen: false,
    customSubmit: true,
  },
  {
    id: BillingSteps.BillingDetails,
    title: "Billing Details",
    component: <BillingManagementDetails />,
    order: 5,
    canOpen: false,
    customSubmit: true,
  },
  {
    id: BillingSteps.Review,
    title: "Review & Submit",
    component: <Review />,
    order: 6,
    canOpen: false,
    customSubmit: true,
  },
];

export const MANAGED_BILLING_STEPS: BillingStepType[] = [
  {
    id: BillingSteps.Payment,
    title: "Payment",
    component: <ManagedPayment />,
    order: 1,
    canOpen: false,
    customSubmit: true,
  },
  {
    id: BillingSteps.BillingDetails,
    title: "Billing Details",
    component: <ManagedBillingManagementDetails />,
    order: 2,
    canOpen: false,
    customSubmit: true,
  },
  {
    id: BillingSteps.Review,
    title: "Review & Submit",
    component: <ManagedReview />,
    order: 3,
    canOpen: false,
    customSubmit: true,
  },
];

export const BILLING_OVERVIEW_TABS: BillingDetailTabsType[] = [
  {
    id: BillingOverviewTabs.CreditUsage,
    title: "Credit usage",
    component: <CreditUsage />,
    order: 1,
  },
  {
    id: BillingOverviewTabs.SubscriptionDetails,
    title: "Subscription details",
    component: <SubscriptionDetails />,
    order: 1,
  },
  {
    id: BillingOverviewTabs.Invoices,
    title: "Invoices",
    component: <Invoices />,
    order: 2,
  },
  {
    id: BillingOverviewTabs.BillingDetails,
    title: "Billing details",
    component: <BillingOverviewDetails />,
    order: 3,
  },
];

export enum CreditUsageType {
  DataSearch = 0,
  DataEnrichment = 1,
  DataCleaning = 2,
  EmailValidation = 3,
}

export const CreditUsageTypeLabels = {
  [CreditUsageType.DataSearch]: "Data Search",
  [CreditUsageType.DataEnrichment]: "Data Enrichment",
  [CreditUsageType.DataCleaning]: "Data Cleaning",
  [CreditUsageType.EmailValidation]: "Email Validation",
};

export const CreditUsageOptions = [
  CreditUsageType.DataSearch,
  CreditUsageType.DataCleaning,
  CreditUsageType.DataEnrichment,
  CreditUsageType.EmailValidation,
];
