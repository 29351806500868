import { LoadingButton } from "@mui/lab";
import { Alert, Divider, Stack, Typography, useTheme } from "@mui/material";
import { AddressElement, useElements } from "@stripe/react-stripe-js";
import { StripeAddressElementOptions } from "@stripe/stripe-js";
import { FC, ReactElement, useState } from "react";
import { useDispatch } from "react-redux";
import { GENERIC_ERROR_MESSAGE } from "../../../../../../../constants/snackbar-messages";
import { useAccountBilling } from "../../../../../../../hooks/useAccountBilling";
import { openSnackbar } from "../../../../../../../redux/reducers/snackbarReducer";
import AccountService from "../../../../../../../services/accountService";
import {
  BillingSteps,
  SELF_SERVE_BILLING_STEPS,
} from "../../../../constants/account-billing";

interface AddressFormProps {
  onUpdateCompleted?(): void;
}

const AddressForm: FC<AddressFormProps> = (props): ReactElement => {
  const { onUpdateCompleted } = props;
  const { billing, setCurrentStep, setBilling } = useAccountBilling();

  const elements = useElements();
  const dispatch = useDispatch();

  const theme = useTheme();

  const [isConfirming, setIsConfirming] = useState(false);
  const [error, setError] = useState("");

  const billingDetails = billing?.billing_details;

  const options: StripeAddressElementOptions = {
    mode: "billing",
    defaultValues: {
      address: billingDetails?.address || { country: "" },
      name: billingDetails?.name,
      phone: billingDetails?.phone,
    },
    fields: { phone: "always" },
  };

  const submitDetails = async () => {
    setIsConfirming(true);

    const { error: submitErrors } = await elements.submit();
    if (submitErrors) {
      setError(submitErrors.message);
      setIsConfirming(false);
      return;
    }

    const addressElement = elements.getElement("address");
    const { complete, value } = await addressElement.getValue();

    if (complete) {
      const { error } = await AccountService.updateBillingAddress(value);
      if (error)
        dispatch(
          openSnackbar({
            open: true,
            severity: "error",
            message: GENERIC_ERROR_MESSAGE,
          }),
        );
      else {
        setBilling({ ...billing, billing_details: value });
        const nextStep = SELF_SERVE_BILLING_STEPS.find(
          (s) => s.id === BillingSteps.Review,
        );
        setCurrentStep(nextStep);
        onUpdateCompleted && onUpdateCompleted();
        setError("");
      }
    }
    setIsConfirming(false);
  };

  return (
    <Stack>
      <Stack p={theme.spacing(3)} spacing={theme.spacing(2)}>
        {error && (
          <Alert severity="error">
            <Typography variant="body2">{error}</Typography>
          </Alert>
        )}
        <AddressElement options={options} />
      </Stack>
      <Divider flexItem />
      <Stack
        direction={"row"}
        p={theme.spacing(2)}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        <LoadingButton
          variant="contained"
          loading={isConfirming}
          onClick={submitDetails}
        >
          Confirm details
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default AddressForm;
