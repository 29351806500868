import { HelpOutline } from "@mui/icons-material";
import { Divider, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { FC, ReactElement } from "react";
import CouponChip from "../../../../../../../components/custom/chips/CouponChip";
import { UbicoInvoice } from "../../../../../../../constants/data-types";
import { getDiscountedValue } from "../../../../utils/billing";
import InvoiceItemComponent from "./InvoiceItemComponent";

interface InvoiceDetailsProps {
  invoice: UbicoInvoice
}

const InvoiceDetails: FC<InvoiceDetailsProps> = (props): ReactElement => {
  const { invoice } = props

  const theme = useTheme()

  let previousPrice = invoice?.subtotal

  return (
    <>
      {invoice?.lines?.map((line, idx) => {
        let previousLinePrice = line?.amount
        return (
          <Stack key={idx}>
            <Divider flexItem />
            <InvoiceItemComponent>
              <Typography variant="body2">
                {line.description}
              </Typography>
              <Typography variant="body2">{(line.amount).toFixed(2)}$</Typography>
            </InvoiceItemComponent>
            {line?.discounts?.map((d, idx) => {
              const discountedValue = getDiscountedValue(previousLinePrice, d)
              previousLinePrice -= Number(discountedValue)
              return (
                <Stack key={idx}>
                  <Divider flexItem/>
                  <InvoiceItemComponent pl={theme.spacing(8)}>
                    <CouponChip discount={d}/>
                    <Typography variant="body2">-{discountedValue}$</Typography>
                  </InvoiceItemComponent>
                </Stack>
              );
            })}
          </Stack>
        );
      })}
      <Stack>
        <Divider flexItem sx={{borderBottomWidth: 3}}/>
        <InvoiceItemComponent>
          <Typography  variant="body2" fontWeight={'bolder'}>Subtotal</Typography>
          <Typography  variant="body2" fontWeight={'bolder'}>{(invoice?.subtotal || 0).toFixed(2)}$</Typography>
        </InvoiceItemComponent>
      </Stack>
      {invoice?.discounts?.map((d, idx) => {
        // If customer updates an ongoing subscription, discounts are not applied
        const discountNotApplied = invoice?.subtotal === invoice?.total_excluding_tax
        const discountedValue = discountNotApplied ? 0 : getDiscountedValue(previousPrice, d)
        previousPrice -= Number(discountedValue)
        return (
          <Stack key={idx}>
            <Divider flexItem/>
            <InvoiceItemComponent pl={theme.spacing(8)}>
              <CouponChip discount={d} endText="subscription "/>
              <Typography variant="body2">-{discountedValue}$</Typography>
            </InvoiceItemComponent>
          </Stack>
        );
      })}
      <Stack>
        <Divider flexItem  sx={{borderBottomWidth: 3}}/>
        <InvoiceItemComponent>
          <Typography variant="body2" fontWeight={'bolder'}>Total excluding taxes</Typography>
          <Typography variant="body2" fontWeight={'bolder'}>{(invoice?.total_excluding_tax || 0).toFixed(2)}$</Typography>
        </InvoiceItemComponent>
      </Stack>
      {invoice?.taxes?.map((tax, idx) => {
        return (
          <Stack key={idx}>
            <Divider flexItem />
            <InvoiceItemComponent>
              <Typography variant="body2">
                {tax.display_name} {"("}
                {tax.percentage}%{")"}
              </Typography>
              <Typography variant="body2">{(tax.amount || 0).toFixed(2)}$</Typography>
            </InvoiceItemComponent>
          </Stack>
        );
      })}
      <>
        {invoice?.applied_balance ? (
          <>
            <Divider flexItem />
            <InvoiceItemComponent>
              <Typography variant="subtitle2" fontWeight={"bolder"}>
                Total
              </Typography>
              <Typography variant="body2" fontWeight={"bolder"}>
                {(invoice?.total_including_tax || 0).toFixed(2)}$
              </Typography>
            </InvoiceItemComponent>
            <Divider flexItem />
            <InvoiceItemComponent>
              <Typography variant="subtitle2" fontWeight={"bolder"}>
                Applied balance
              </Typography>
              <Typography variant="body2" fontWeight={"bolder"}>
                {"("}
                {(invoice?.applied_balance || 0).toFixed(2)}${")"}
              </Typography>
            </InvoiceItemComponent>
            <Divider flexItem />
            <InvoiceItemComponent spacing={theme.spacing(2)}>
              <Typography variant="subtitle1" fontWeight={"bolder"}>
                Total Due
              </Typography>
              <Typography variant="body1" fontWeight={"bolder"}>
                {(invoice?.amount_due || 0).toFixed(2)}$
              </Typography>
            </InvoiceItemComponent>
          </>
        ) : (
          <>
            <Divider flexItem />
            <InvoiceItemComponent spacing={theme.spacing(2)}>
              <Typography variant="subtitle1" fontWeight={"bolder"}>
                Total Due
              </Typography>
              <Typography variant="body1" fontWeight={"bolder"}>
                {(invoice?.amount_due || 0).toFixed(2)}$
              </Typography>
            </InvoiceItemComponent>
          </>
        )}
        {invoice?.total_including_tax < 0 && (
          <>
            <Divider />
            <InvoiceItemComponent spacing={theme.spacing(2)}>
              <Stack
                direction={"row"}
                spacing={theme.spacing(1)}
                alignItems={"center"}
              >
                <Typography variant="subtitle1" fontWeight={"bolder"}>
                  Credited to your account
                </Typography>
                <Tooltip title="Credited amounts will be deducted on your next invoice.">
                  <HelpOutline fontSize="small" />
                </Tooltip>
              </Stack>
              <Typography variant="body1" fontWeight={"bolder"}>
                {invoice?.total_including_tax || 0}$
              </Typography>
            </InvoiceItemComponent>
          </>
        )}
      </>
    </>
  )
}

export default InvoiceDetails
