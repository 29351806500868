import { Elements } from "@stripe/react-stripe-js";
import { Appearance } from "@stripe/stripe-js";
import { FC, ReactElement } from "react";

import { useTheme } from "@mui/material";
import { useStripePromise } from "../../../../../../../hooks/useStripePromise";
import { THEME_MODE } from "../../../../../../../theme";
import AddressForm from "./AddressForm";

interface AddressFormWrapperProps {
  onUpdateCompleted?(): void;
}

const AddressFormWrapper: FC<AddressFormWrapperProps> = (
  props,
): ReactElement => {
  const { onUpdateCompleted } = props;

  const theme = useTheme();
  const { stripePromise } = useStripePromise();

  const appearance: Appearance = {
    theme: theme.palette.mode === THEME_MODE.Dark ? "night" : "stripe",
    variables: {
      colorPrimary: theme.palette.primary.main,
      colorBackground: theme.palette.background.default,
      colorSuccess: theme.palette.primary.main,
      colorDanger: theme.palette.error.main,
    },
  };

  const options = {
    appearance,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <AddressForm onUpdateCompleted={onUpdateCompleted} />
    </Elements>
  );
};

export default AddressFormWrapper;
