import { Circle, Flag } from "@mui/icons-material";
import {
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { FC, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Notification } from "../../constants/data-types";
import { NotificationTypeLabels } from "../../constants/notifications/types";
import { GENERIC_ERROR_MESSAGE } from "../../constants/snackbar-messages";
import { setNotifications } from "../../redux/reducers/notificationsReducer";
import { openSnackbar } from "../../redux/reducers/snackbarReducer";
import { RootState } from "../../redux/store";
import NotificationService from "../../services/notificationService";
import { getTimeSince, sortByDate } from "../../utils/date-utils";
import { NAVBAR_LEFT_ELEVATION } from "../navbar/left/NavbarLeft";

const NotificationsComponent: FC = (): ReactElement => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const notifications = useSelector((state: RootState) => state.notifications);

  const [loading, setLoading] = useState(true);
  const [showOnlyUnread, setShowOnlyUnread] = useState(false);
  const [hoveredNotification, setHoveredNotification] =
    useState<Notification>(null);
  const [notificationsDisplayed, setNotificationsDisplayed] =
    useState<Notification[]>(notifications);

  const updateNotificationsData = (updatedNotifications: Notification[]) => {
    // Update redux state
    dispatch(setNotifications(updatedNotifications));
    // Update component state
    if (showOnlyUnread)
      setNotificationsDisplayed(updatedNotifications.filter((n) => !n.is_read));
    else setNotificationsDisplayed(updatedNotifications);
  };

  const markAllAsRead = async () => {
    const { data, error } = await NotificationService.readAllNotifications();
    if (error)
      dispatch(
        openSnackbar({
          open: true,
          severity: "error",
          message: GENERIC_ERROR_MESSAGE,
        }),
      );
    else updateNotificationsData(data);
  };

  const updateNotification = async (notification: Notification) => {
    const { error, data } = await NotificationService.updateNotification(
      notification.id,
      !notification.is_read,
    );
    if (error)
      dispatch(
        openSnackbar({
          open: true,
          severity: "error",
          message: GENERIC_ERROR_MESSAGE,
        }),
      );
    else {
      const updatedNotifications = notifications.map((m: Notification) =>
        m.id === data.id ? data : m,
      );
      updateNotificationsData(updatedNotifications);
    }
  };

  const onOnlyReadChange = async (checked: boolean) => {
    setShowOnlyUnread(checked);
    if (checked)
      setNotificationsDisplayed(
        notificationsDisplayed.filter((n) => !n.is_read),
      );
    else setNotificationsDisplayed(notifications);
  };

  const getNotifications = async () => {
    setLoading(true);
    const { error, data } = await NotificationService.getNotifications();
    if (!error && data) dispatch(setNotifications(data));
    setLoading(false);
  };

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    setNotificationsDisplayed(notifications);
  }, [notifications]);

  return (
    <Stack>
      <Stack
        position={"sticky"}
        top={0}
        zIndex={NAVBAR_LEFT_ELEVATION + 1000}
        bgcolor={theme.palette.background.default}
        p={theme.spacing(1)}
        pl={theme.spacing(2)}
        pr={theme.spacing(2)}
        justifyContent={"space-between"}
        alignItems={"center"}
        direction={"row"}
      >
        <Typography
          variant="body2"
          color="text.disabled"
          fontWeight={"bold"}
          pl={theme.spacing(1)}
        >
          LATEST
        </Typography>
        <Stack alignItems={"flex-end"}>
          <FormControlLabel
            control={
              <Switch
                checked={showOnlyUnread}
                size="small"
                onChange={(_, checked) => onOnlyReadChange(checked)}
              />
            }
            label={<Typography variant="caption">Show only unread</Typography>}
            labelPlacement="start"
          />
          <Link
            fontSize={13}
            sx={{ cursor: "pointer" }}
            onClick={markAllAsRead}
          >
            Mark all as read
          </Link>
        </Stack>
      </Stack>
      {loading ? (
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
          height={"90vh"}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <List>
          {sortByDate(notificationsDisplayed, "created_at").map(
            (notification: Notification) => {
              const isHovered = notification.id === hoveredNotification?.id;
              return (
                <div key={notification.id}>
                  <ListItemButton
                    sx={{
                      width: "100%",
                      pl: theme.spacing(3),
                      pr: theme.spacing(3),
                    }}
                    disableTouchRipple
                    onMouseEnter={() => setHoveredNotification(notification)}
                    onMouseLeave={() => setHoveredNotification(null)}
                  >
                    <Stack width={"100%"}>
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <Stack direction={"row"} spacing={theme.spacing(2)}>
                          <Typography>
                            {
                              NotificationTypeLabels[
                                notification.notification_type
                              ]
                            }
                          </Typography>
                          <Typography variant="caption" color={"text.disabled"}>
                            {getTimeSince(notification.created_at)}
                          </Typography>
                        </Stack>
                        <Tooltip
                          title={
                            notification.is_read
                              ? "Mark as unread"
                              : "Mark as read"
                          }
                          placement="right"
                        >
                          <IconButton
                            sx={{
                              width: 20,
                              height: 20,
                              opacity: 0.7,
                              color: theme.palette.primary.main,
                              borderStyle: "solid",
                              border: isHovered ? 1 : 0,
                            }}
                            onClick={() => updateNotification(notification)}
                          >
                            {!notification.is_read && (
                              <Circle sx={{ fontSize: 10 }} color="primary" />
                            )}
                          </IconButton>
                        </Tooltip>
                      </Stack>
                      <Typography fontSize={13} whiteSpace={"normal"}>
                        {notification.message}
                      </Typography>
                    </Stack>
                  </ListItemButton>
                  <Divider />
                </div>
              );
            },
          )}
          <ListItem>
            <ListItemText
              secondary={
                <Stack
                  spacing={theme.spacing(2)}
                  alignItems={"center"}
                  component={"span"}
                >
                  <Flag color="primary" />
                  <Typography
                    sx={{ display: "inline" }}
                    component={"span"}
                    variant="body2"
                    color="text.secondary"
                    whiteSpace={"normal"}
                    textAlign="center"
                  >
                    That's all your notifications from the last 30 days.
                  </Typography>
                </Stack>
              }
              sx={{
                p: theme.spacing(2),
                pb: theme.spacing(5),
                pr: theme.spacing(5),
              }}
            />
          </ListItem>
        </List>
      )}
    </Stack>
  );
};

export default NotificationsComponent;
