import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  productivityTasks: 0,
  draftTasks: 0,
};

export const tasksCountSlice = createSlice({
  name: "productivityTasksCount",
  initialState,
  reducers: {
    setProductivityTasksCount: (state, action: PayloadAction<number>) => {
      state.productivityTasks = action.payload;
    },
    setDraftTasksCount: (state, action: PayloadAction<number>) => {
      state.draftTasks = action.payload;
    },
  },
});

export const { setProductivityTasksCount, setDraftTasksCount } =
  tasksCountSlice.actions;

export default tasksCountSlice.reducer;
