import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UbicoProduct, UbicoSubscription } from "../../constants/data-types";

interface BillingType {
  subscription: UbicoSubscription;
  subscriptionProducts: UbicoProduct[];
  creditProducts: UbicoProduct[];
}

const initialState: BillingType = {
  subscription: {
    id: "",
    created: null,
    current_period_start: null,
    current_period_end: null,
    description: "",
    products: [],
    default_payment_method: {},
    billing_details: {},
    trial_start: null,
    trial_end: null,
    cancel_at: null,
    credits_used: 0,
  },
  subscriptionProducts: [],
  creditProducts: [],
};

export const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    setSubscription: (state, action: PayloadAction<UbicoSubscription>) => {
      state.subscription = action.payload;
    },
    setSubscriptionProducts: (state, action: PayloadAction<UbicoProduct[]>) => {
      state.subscriptionProducts = action.payload;
    },
    setCreditProducts: (state, action: PayloadAction<UbicoProduct[]>) => {
      state.creditProducts = action.payload;
    },
  },
});

export const { setSubscription, setSubscriptionProducts, setCreditProducts } =
  billingSlice.actions;

export default billingSlice.reducer;
