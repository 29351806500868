import { TextField } from "@mui/material";
import { styled, darken } from "@mui/material/styles";

export const UbicoTextField = styled(TextField)(({ theme }) => ({
  label: {
    color: theme.palette.text.primary,
  },
  color: theme.palette.primary.main,
  "&:hover": {
    color: darken(theme.palette.primary.main, 0.2),
  },
  "& .MuiFormHelperText-root": {
    color: theme.palette.text.disabled,
    "&.Mui-error": {
      color: theme.palette.error.main,
    },
  },
}));
