import { FC } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { Account } from "../constants/data-types";
import { RootState } from "../redux/store";
import { HOME_ROUTE } from "../routes/appRoutes";

interface UnprotectedRouteProps {
  redirectPath?: string;
}

const UnprotectedRoute: FC<UnprotectedRouteProps> = ({
  redirectPath = HOME_ROUTE,
}: UnprotectedRouteProps) => {
  const { is_logged_in } = useSelector((state: RootState) => state.auth);
  const account: Account = useSelector((state: RootState) => state.account);

  if (is_logged_in && account?.completed_account_setup) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default UnprotectedRoute;
