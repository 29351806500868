import { AxiosService, UbicoAPIResponse } from "../api-provider";
import { API_PATH } from "../constants/paths";
import { GENERIC_ERROR_MESSAGE } from "../constants/snackbar-messages";
import { Timezone } from "../pages/campaigns/constants/data-types";

const getTimezones = async (): Promise<UbicoAPIResponse<Timezone[]>> => {
  try {
    const attributes = await AxiosService.get(`${API_PATH}/timezones/`);
    return { error: false, data: attributes.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const TimezoneService = {
  getTimezones,
};

export default TimezoneService;
