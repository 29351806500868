import { Check, HelpOutline } from "@mui/icons-material";
import {
  Chip,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { FC, ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CouponChip from "../../../../../../../components/custom/chips/CouponChip";
import MultipleCouponChip from "../../../../../../../components/custom/chips/MultipleCouponChip";
import { UbicoTextField } from "../../../../../../../components/custom/textfields/TextField";
import {
  UbicoProduct,
  UbicoSubscription,
} from "../../../../../../../constants/data-types";
import { useAccountBilling } from "../../../../../../../hooks/useAccountBilling";
import { RootState } from "../../../../../../../redux/store";
import InviteService from "../../../../../../../services/inviteService";
import { BillingSubscriptionStatus, ProductTypes } from "../../../../constants/account-billing";
import { formatPricingRecurrence, getDiscountedValue } from "../../../../utils/billing";

const MAX_FEATURES_PER_COLUMN = 3;

const PlanConfiguration: FC = (): ReactElement => {
  const theme = useTheme();

  const { billing, setBilling } = useAccountBilling();

  const [membersCount, setMembersCount] = useState(1);
  const [loadingMembers, setLoadingMembers] = useState(true);

  const teamMembers = useSelector((state: RootState) => state.team);

  const subscription: UbicoSubscription = useSelector(
    (state: RootState) => state.billing.subscription,
  );
  const subscriptionProducts: UbicoProduct[] = useSelector(
    (state: RootState) => state.billing.subscriptionProducts,
  );

  const userProduct = subscriptionProducts.find(
    (p) => p.id === billing?.plan_product_id,
  );
  const userPrice = userProduct?.prices?.find(
    (p) => p.recurrence?.interval === billing?.recurrence?.interval,
  );

  const numFeatureColumns = Math.ceil(
    userProduct?.features?.length / MAX_FEATURES_PER_COLUMN,
  );
  const columns = Array.from({ length: numFeatureColumns }, (_, columnIndex) =>
    userProduct?.features.slice(
      columnIndex * MAX_FEATURES_PER_COLUMN,
      columnIndex * MAX_FEATURES_PER_COLUMN + MAX_FEATURES_PER_COLUMN,
    ),
  );

  const updateQuantity = (value: number) => {
    if (value > 0) setBilling({ ...billing, plan_quantity: value });
  };

  const planProduct = subscription?.products?.find(
    (p) => p.type === ProductTypes.Plan,
  );
  const discounts = planProduct?.discounts
  let cost = userPrice?.amount * billing?.plan_quantity

  discounts?.map((d) => {
    cost -= Number(getDiscountedValue(cost, d))
  })

  const singleDiscount = discounts?.length === 1 ? discounts[0] : null
  const discountEnd = singleDiscount?.expires ? new Date(singleDiscount?.expires * 1000) : null
  const discountEndText = discountEnd ? `Until ${discountEnd}` : ''

  const currentDate = new Date();
  const trialEnd = subscription?.trial_end
    ? new Date(subscription?.trial_end * 1000)
    : null;
  const trialDataDiff = trialEnd
    ? Math.abs(currentDate?.getTime() - trialEnd?.getTime())
    : null;
  const subscriptionEnd = trialDataDiff
    ? Math.ceil(trialDataDiff / (1000 * 3600 * 24))
    : null;

  const getMembers = async () => {
    setLoadingMembers(true);
    // Get invites
    const { error: inviteError, data: inviteData } =
      await InviteService.getInvites();
    if (!inviteError && inviteData) {
      const totalUsers = teamMembers.length + inviteData.length;
      setMembersCount(totalUsers);
    }
    setLoadingMembers(false);
  };

  useEffect(() => {
    if (membersCount)
      updateQuantity(Math.max(membersCount, billing?.plan_quantity));
  }, [membersCount]);

  useEffect(() => {
    setTimeout(() => {
      getMembers();
    }, 1000); // Need to add delay, or else current product quantity overrides the minimum possible.
  }, [subscription?.id]);

  return (
    <Stack>
      <Divider flexItem />
      <Stack spacing={theme.spacing(2)} padding={theme.spacing(3)}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Stack
            direction={"row"}
            spacing={theme.spacing(2)}
            alignItems={"center"}
          >
            <Typography variant="h6" fontWeight={"bolder"}>
              {userProduct?.name}
            </Typography>
            {subscription?.status === BillingSubscriptionStatus.Trialing &&
              subscriptionEnd > 0 && (
                <Chip
                  variant="outlined"
                  size="small"
                  color="info"
                  label={`Trial ends in ${subscriptionEnd} ${subscriptionEnd > 1 ? "days" : "day"}`}
                />
              )}
            {subscriptionEnd < 0 && (
              <Chip
                variant="outlined"
                size="small"
                color="success"
                label={"You have trialed this product"}
              />
            )}
          </Stack>
          <Stack
            direction={"row"}
            spacing={theme.spacing(2)}
            alignItems={"center"}
          >
            {discounts?.length > 0 &&
              (
                singleDiscount ?
                  (
                    <Tooltip title={discountEndText}>
                      <CouponChip discount={singleDiscount} endText="subscription"/>
                    </Tooltip>
                  )
                  :
                  <MultipleCouponChip/>
              )
            }
            <Stack direction={"row"} spacing={theme.spacing(0.5)}>
              <Typography color={"primary"}>$</Typography>
              <Typography
                color={"primary"}
                display={"inline"}
                fontWeight={"bolder"}
                variant="h5"
              >
                {cost.toString()}
              </Typography>
              <Typography
                fontSize={13}
                color={"primary"}
                alignSelf={"flex-end"}
                display={"inline"}
              >
                {formatPricingRecurrence(userPrice?.recurrence)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          width={"100%"}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Stack spacing={theme.spacing(1)} width={"100%"}>
            <Grid container spacing={2}>
              {columns.map((column, colIndex) => (
                <Grid item xs={12 / columns.length} key={colIndex}>
                  {column.map((f, index) => (
                    <Stack
                      key={index}
                      direction={"row"}
                      spacing={theme.spacing(1)}
                      alignItems={"center"}
                    >
                      <Check sx={{ fontSize: 13 }} />
                      <Typography variant="body2">{f?.name}</Typography>
                    </Stack>
                  ))}
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          spacing={theme.spacing(1)}
          alignItems={"center"}
        >
          <UbicoTextField
            InputProps={{
              inputProps: { min: Math.max(1, membersCount), max: 20 },
            }}
            sx={{ width: 150 }}
            type="number"
            label="Add more Users"
            value={billing?.plan_quantity}
            onChange={(e) => updateQuantity(Number(e.target.value))}
            disabled={loadingMembers}
          />
          {membersCount > 1 && (
            <Tooltip title="To downgrade the number of seats, you might need to delete users/invitations in your workspace">
              <HelpOutline fontSize="small" />
            </Tooltip>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PlanConfiguration;
