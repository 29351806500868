import { Box } from "@mui/material";
import { FC, ReactElement } from "react";
import UbicoDialog from "../../../../../../components/custom/Dialog";
import CardFormWrapper from "../management/payment/CardFormWrapper";

interface CardUpdateDialogProps {
  open: boolean;
  close(): void;
}

const CardUpdateDialog: FC<CardUpdateDialogProps> = (props): ReactElement => {
  const { open, close } = props;

  return (
    <UbicoDialog
      maxWidth={false}
      title="Update card"
      open={open}
      handleClose={close}
    >
      <Box minWidth={600}>
        <CardFormWrapper onSetupComplete={close} />
      </Box>
    </UbicoDialog>
  );
};

export default CardUpdateDialog;
