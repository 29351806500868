import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Profile } from "../../constants/data-types";

const initialState: Profile[] = [];

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setTeam: (state, action: PayloadAction<Profile[]>) => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const { setTeam } = teamSlice.actions;

export default teamSlice.reducer;
