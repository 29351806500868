import { GridFilterItem } from "@mui/x-data-grid-pro";
import { AxiosService, UbicoAPIResponse } from "../api-provider";
import { DraftTask, PaginatedData } from "../constants/data-types";
import { buildFilter } from "../constants/filters";
import { API_PATH } from "../constants/paths";
import { GENERIC_ERROR_MESSAGE } from "../constants/snackbar-messages";

const getMyPendingDraftsCount = async (
  workspace_id: number,
): Promise<UbicoAPIResponse<number>> => {
  try {
    const tasks = await AxiosService.get(
      `${API_PATH}/workspaces/${workspace_id}/draft-tasks/pending/`,
    );
    return { error: false, data: tasks.data.count, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getDrafts = async (
  workspace_id: number,
  page: number,
  pageSize: number,
  filters?: Array<GridFilterItem>,
  searchFilters?: Array<any>,
): Promise<UbicoAPIResponse<PaginatedData<DraftTask> & Array<DraftTask>>> => {
  try {
    const url = `${API_PATH}/workspaces/${workspace_id}/draft-tasks/`;
    const params = {};

    // Add search filters on url
    if (searchFilters?.length > 0) params["search"] = searchFilters.join(", ");

    // Add filters on url
    filters?.map((filter: GridFilterItem) => {
      if (filter.field && filter.operator)
        buildFilter(params, filter.field, filter.operator, filter.value);
    });

    // Add pagination
    if (page !== null && pageSize !== null) {
      params["page"] = page;
      params["page_size"] = pageSize;
    }

    const tasks = await AxiosService.get(url, { params: params });

    return { error: false, data: tasks.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const updateDraft = async (
  workspace_id: number,
  task_id: number,
  data: any,
): Promise<UbicoAPIResponse<DraftTask>> => {
  try {
    const tasks = await AxiosService.patch(
      `${API_PATH}/workspaces/${workspace_id}/draft-tasks/${task_id}/`,
      data,
    );
    return { error: false, data: tasks.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const DraftTaskService = {
  getDrafts,
  getMyPendingDraftsCount,
  updateDraft,
};

export default DraftTaskService;
