import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SendingSchedule } from "../../constants/data-types";

const initialState: SendingSchedule[] = [];

export const schedulesSlice = createSlice({
  name: "schedules",
  initialState,
  reducers: {
    setSendingSchedules: (state, action: PayloadAction<SendingSchedule[]>) => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const { setSendingSchedules } = schedulesSlice.actions;

export default schedulesSlice.reducer;
