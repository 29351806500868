import { Stack, useTheme } from "@mui/material";
import { FC, ReactElement } from "react";
import { useAccountBilling } from "../../../../../../../hooks/useAccountBilling";
import { BillingSteps } from "../../../../constants/account-billing";
import AddressFormWrapper from "../payment/AddressFormWrapper";

const BillingDetails: FC = (): ReactElement => {
  const theme = useTheme();

  const { currentStep } = useAccountBilling();

  if (currentStep?.id !== BillingSteps.BillingDetails) return <></>;

  return (
    <Stack spacing={theme.spacing(3)}>
      <Stack width={"100%"}>
        <AddressFormWrapper />
      </Stack>
    </Stack>
  );
};

export default BillingDetails;
