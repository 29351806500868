import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Alert, Snackbar, useTheme } from "@mui/material";
import {
  closeSnackbar,
  SNACKBAR_DEFAULT_DURATION,
} from "../redux/reducers/snackbarReducer";
import { RootState } from "../redux/store";

const AppSnackbar: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const snackbar = useSelector((state: RootState) => state.snackbar);

  const handleClose = () => {
    dispatch(closeSnackbar());
  };

  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={SNACKBAR_DEFAULT_DURATION}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      ClickAwayListenerProps={{ onClickAway: () => null }}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={snackbar.severity}
        variant="filled"
        sx={{ color: theme.palette.common.white }}
      >
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
};

export default AppSnackbar;
