import { styled } from "@mui/material";
import { FC, ReactElement } from "react";
import { Link, LinkProps } from "react-router-dom";

const StyledUbicoNavLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

interface UbicoNavLinkProps extends LinkProps {
  fontSize?: "small" | "medium" | "large";
}

const UbicoNavLink: FC<UbicoNavLinkProps> = (props): ReactElement => {
  const { fontSize = "medium", ...otherProps } = props;

  const getFontSize = (fontSize) => {
    switch (fontSize) {
      case "small":
        return 13;
      case "medium":
        return 15;
      case "large":
        return 17;
      default:
        15;
    }
  };
  return (
    <StyledUbicoNavLink
      style={{ fontSize: getFontSize(fontSize) }}
      {...otherProps}
    />
  );
};

export default UbicoNavLink;
