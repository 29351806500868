import { Box, useTheme } from "@mui/material";
import {
  DataGridPro,
  DataGridProProps,
  GridLogicOperator,
} from "@mui/x-data-grid-pro";

interface DataGridProps extends DataGridProProps {
  minHeight?: string;
}

const UbicoDataGrid: React.FC<DataGridProps> = ({
  minHeight = "77vh",
  ...props
}) => {
  const theme = useTheme();

  return (
    <Box display={"grid"} height={minHeight}>
      <DataGridPro
        {...props}
        hideFooterSelectedRowCount
        slotProps={{
          filterPanel: {
            logicOperators: [GridLogicOperator.And],
            filterFormProps: {
              logicOperatorInputProps: {
                variant: 'outlined',
                size: 'small',
              },
              columnInputProps: {
                variant: 'outlined',
                size: 'small',
              },
              operatorInputProps: {
                variant: 'outlined',
                size: 'small'
              },
              valueInputProps: {
                InputComponentProps: {
                  variant: 'outlined',
                  size: 'small',
                },
              },
            },
            sx: {
              // Customize inputs using css selectors
              '& .MuiDataGrid-filterForm': { p: 2 },
              '& .MuiDataGrid-filterFormDeleteIcon': { mr: 2, fontSize: 2 },
              '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
              '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150, "& input": { fontSize: 10} },
              '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
              '& .MuiDataGrid-filterFormValueInput': { width: 200 },
              '& .MuiInputBase-input': { fontSize: 15, pt: theme.spacing(1), pb: theme.spacing(1) }
            },
          },
          ...props.slotProps,
        }}
        filterDebounceMs={500}
        sx={{
          borderStyle: "none",
          "& .MuiDataGrid-cell, & .MuiDataGrid-columnHeadersInner": {
            borderStyle: "none",
          },
          ".MuiDataGrid-footerContainer": {
            borderColor: theme.palette.divider,
          },
          "& .MuiDataGrid-iconSeparator": {
            color: theme.palette.divider,
          },
          "& .MuiDataGrid-checkboxInput": {
            color: theme.palette.text.primary,
          },
          "& .MuiDataGrid-topContainer": {
            color: theme.palette.divider,
            borderBottom: 1,
          },
          "& .MuiDataGrid-withBorderColor": {
            outline: "none !important",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
            color: theme.palette.text.primary,
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
            outline: "none !important",
          },
        }}
      />
    </Box>
  );
};

export default UbicoDataGrid;
