import { LoadingButton } from "@mui/lab";
import { Alert, Box, Link, Stack, Typography, useTheme } from "@mui/material";
import { FC, ReactElement, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmImage from "../../../../../../assets/images/dazzle-calendar-and-alarm-for-time-management.gif";
import {
  Profile,
  UbicoSubscription,
} from "../../../../../../constants/data-types";
import { UBICO_SUPPORT_URL } from "../../../../../../constants/urls";
import { useSubscriptionCancellation } from "../../../../../../hooks/useSubscriptionCancellation";
import { setSubscription } from "../../../../../../redux/reducers/billingReducer";
import { RootState } from "../../../../../../redux/store";
import { HOME_ROUTE } from "../../../../../../routes/appRoutes";
import AccountService from "../../../../../../services/accountService";

const SubscriptionCancelConfirm: FC = (): ReactElement => {
  const { feedback, comment } = useSubscriptionCancellation();

  const subscription: UbicoSubscription = useSelector(
    (state: RootState) => state.billing.subscription,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const complete = async () => {
    setLoading(true);
    const { error, data, message } = await AccountService.cancelSubscription(
      feedback,
      comment,
    );
    if (!error && data) {
      dispatch(setSubscription(data));
      navigate(HOME_ROUTE);
      setError("");
    } else {
      setError(message);
    }
    setLoading(false);
  };

  const theme = useTheme();

  const profile: Profile = useSelector((state: RootState) => state.profile);

  const subscriptionCancelDate = subscription?.current_period_end
    ? new Date(subscription?.current_period_end * 1000).toDateString()
    : "";

  return (
    <Stack spacing={theme.spacing(2)}>
      <Typography variant="h6">
        {profile?.user?.first_name}, we hope to see you back again soon! 😊
      </Typography>
      <Alert severity="success" variant="outlined">
        Your subscription will be canceled at the end of the current period (
        {subscriptionCancelDate}) and you will not be charged anymore.
      </Alert>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography>
          We thrive to improve and provide a better product by listening to our
          customers. Feel free to reach out to{" "}
          <Link href={UBICO_SUPPORT_URL}>support</Link> for any questions.
        </Typography>
        <Box
          component="img"
          src={ConfirmImage}
          sx={{
            maxHeight: 150,
            maxWidth: 150,
            width: "auto",
            height: "auto",
            display: "block",
          }}
        />
      </Stack>
      {error && (
        <Alert severity="error" variant="outlined">
          {error}
        </Alert>
      )}
      <LoadingButton
        loading={loading}
        onClick={complete}
        sx={{ textTransform: "capitalize" }}
        variant="contained"
        color="error"
      >
        Definitely cancel my subscription
      </LoadingButton>
    </Stack>
  );
};

export default SubscriptionCancelConfirm;
