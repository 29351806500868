import { ChevronLeft, ChevronRight, Download } from "@mui/icons-material";
import {
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { FC, ReactElement, useEffect, useState } from "react";
import UbicoDataGrid from "../../../../../../components/custom/datagrids/DataGrid";
import { UbicoInvoice } from "../../../../../../constants/data-types";
import AccountService from "../../../../../../services/accountService";

const Invoices: FC = (): ReactElement => {
  const [invoices, setInvoices] = useState<UbicoInvoice[]>([]);

  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);

  const invoicesColumns: GridColDef[] = [
    {
      field: "created",
      headerName: "Date",
      flex: 1,
      valueGetter: (value) => new Date(value * 1000).toDateString(),
    },
    {
      field: "amount_due",
      headerName: "Amount",
      flex: 1,
      valueGetter: (value) => value,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Stack
          direction={"row"}
          width={"100%"}
          justifyContent={"center"}
          height={"100%"}
          alignItems={"center"}
        >
          <Typography variant="body2" textTransform={"capitalize"}>
            {params.value}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "invoice_pdf",
      headerName: "Invoice",
      maxWidth: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <IconButton size="small" href={params.value}>
          <Download fontSize="small" />
        </IconButton>
      ),
    },
  ];

  const getInvoices = async (startingAfter?: string, endingFrom?: string) => {
    setIsLoading(true);
    const { error, data } = await AccountService.getInvoices(
      startingAfter,
      endingFrom,
    );
    if (!error && data) {
      setInvoices(data?.data || []);
      if (startingAfter) {
        setHasNext(data.has_more);
        setHasPrevious(true);
      } else if (endingFrom) {
        setHasNext(true);
        setHasPrevious(data.has_more);
      } else if (!startingAfter && !endingFrom) {
        setHasNext(data.has_more);
      }
    }
    setIsLoading(false);
  };

  const onPreviousPage = () => {
    const endingBefore: UbicoInvoice =
      invoices?.length > 0 ? invoices[0] : null;
    getInvoices(null, endingBefore?.id);
  };

  const onNextPage = () => {
    const startingAfter: UbicoInvoice =
      invoices?.length > 0 ? invoices.at(-1) : null;
    getInvoices(startingAfter?.id, null);
  };

  useEffect(() => {
    getInvoices();
  }, []);

  interface PaginationComponentProps {
    hasPrevious: boolean;
    hasNext: boolean;
    onNext(): void;
    onPrevious(): void;
  }
  const PaginationComponent: FC<PaginationComponentProps> = (props) => {
    const { hasPrevious = false, hasNext = false, onNext, onPrevious } = props;
    return (
      <Stack spacing={theme.spacing(1)}>
        <Divider flexItem />
        <Stack direction={"row"} justifyContent={"flex-end"}>
          <Stack direction={"row"}>
            <IconButton onClick={onPrevious} disabled={!hasPrevious}>
              <ChevronLeft />
            </IconButton>
            <IconButton onClick={onNext} disabled={!hasNext}>
              <ChevronRight />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    );
  };

  return (
    <Stack>
      <UbicoDataGrid
        hideFooterPagination
        columns={invoicesColumns}
        rows={invoices || []}
        loading={isLoading}
        paginationMode="server"
        rowCount={Number.MAX_VALUE}
        slots={{
          footer: () => (
            <PaginationComponent
              hasNext={hasNext}
              hasPrevious={hasPrevious}
              onNext={onNextPage}
              onPrevious={onPreviousPage}
            />
          ),
        }}
      />
    </Stack>
  );
};

export default Invoices;
