import { AxiosService, UbicoAPIResponse } from "../api-provider";
import { Invite } from "../constants/data-types";
import { API_PATH } from "../constants/paths";
import { GENERIC_ERROR_MESSAGE } from "../constants/snackbar-messages";

const getIncomingInvites = async (): Promise<
  UbicoAPIResponse<Array<Invite>>
> => {
  try {
    const invites = await AxiosService.get(`${API_PATH}/invites-received/`);
    return { error: false, data: invites.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const deleteInvite = async (
  invite_id: number,
): Promise<UbicoAPIResponse<any>> => {
  try {
    await AxiosService.delete(`${API_PATH}/invites-received/${invite_id}/`);
    return { error: false, message: "Successfully deleted invite." };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const deleteAccountInvites = async (
  invite_id: number,
): Promise<UbicoAPIResponse<any>> => {
  try {
    await AxiosService.delete(`${API_PATH}/invites/${invite_id}`);
    return {
      error: false,
      message: `Successfully deleted invite.`,
    };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getInvites = async (): Promise<UbicoAPIResponse<Array<Invite>>> => {
  try {
    const invites = await AxiosService.get(`${API_PATH}/invites/`);
    return { error: false, data: invites.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const sendInvites = async (
  invites: Array<any>,
): Promise<UbicoAPIResponse<any>> => {
  try {
    const response = await AxiosService.post(`${API_PATH}/invites/`, {
      invites,
    });

    return {
      error: false,
      message: response.data.data,
    };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error ||
        error.response?.data?.detail ||
        GENERIC_ERROR_MESSAGE,
    };
  }
};

const resendInvite = async (
  invite_email: string,
): Promise<UbicoAPIResponse<any>> => {
  try {
    const response = await AxiosService.post(
      `${API_PATH}/invites/resend_invite/`,
      { email: invite_email },
    );
    return {
      error: false,
      message: response.data.data,
    };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const InviteService = {
  getInvites,
  sendInvites,
  getIncomingInvites,
  deleteInvite,
  deleteAccountInvites,
  resendInvite,
};

export default InviteService;
