import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialState = {
  segment: null,
};

export const overviewStatisticSlice = createSlice({
  name: "overview_params",
  initialState,
  reducers: {
    setStatisticParams: (state, action: PayloadAction<any>) => {
      state.segment = action.payload.segment;
    },
  },
});

export const { setStatisticParams } = overviewStatisticSlice.actions;

export default overviewStatisticSlice.reducer;
