import { PaletteMode, darken, lighten } from "@mui/material";
import { grey } from "@mui/material/colors";
import ubicoColors from "./assets/variables/colors.scss";

export enum THEME_MODE {
  Light = "light",
  Dark = "dark",
}

export const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === THEME_MODE.Light
      ? {
          // palette values for light mode
          primary: {
            main: ubicoColors.primary,
            contrastText: "#fff",
          },
          warning: {
            main: ubicoColors.warning,
            contrastText: "#fff",
          },
          info: {
            main: ubicoColors.info,
            contrastText: "#fff",
          },
          divider: grey[400],
          background: {
            default: grey[50],
            paper: "#fff",
            light: grey[100],
          },
          text: {
            primary: ubicoColors.textPrimary,
            secondary: darken(ubicoColors.textPrimary, 0.7),
          },
          snackbar: {
            main: ubicoColors.primary,
            contrastText: "#fff",
          },
          border: grey[50],
        }
      : {
          // palette values for dark mode
          primary: {
            main: ubicoColors.primary,
            contrastText: "#fff",
          },
          warning: {
            main: ubicoColors.warning,
            contrastText: "#fff",
          },
          info: {
            main: ubicoColors.info,
            contrastText: "#fff",
          },
          divider: grey[600],
          background: {
            default: grey[900],
            paper: grey[800],
            light: grey[800],
          },
          text: {
            primary: lighten(ubicoColors.textPrimary, 1.0),
            secondary: lighten(ubicoColors.textPrimary, 0.7),
          },
          border: grey[50],
        }),
  },
  typography: {
    fontFamily: `"poppins", "Helvetica", "Arial", sans-serif`,
    fontDisplay: "fallback",
  },
});
