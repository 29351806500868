import {
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FC, ReactElement, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UbicoButton from "../../../../../../components/custom/buttons/Button";
import { UbicoSubscription } from "../../../../../../constants/data-types";
import { openSnackbar } from "../../../../../../redux/reducers/snackbarReducer";
import { RootState } from "../../../../../../redux/store";
import AddressFormWrapper from "../management/payment/AddressFormWrapper";
import CardUpdateDialog from "./CardUpdateDialog";

const BillingDetails: FC = (): ReactElement => {
  const subscription: UbicoSubscription = useSelector(
    (state: RootState) => state.billing.subscription,
  );

  const theme = useTheme();
  const dispatch = useDispatch();

  const [showCard, setShowCard] = useState(false);

  const onUpdateComplete = () => {
    dispatch(
      openSnackbar({
        open: true,
        severity: "success",
        message: "Successfully updated billing details!",
      }),
    );
  };

  const hasCard = Boolean(subscription?.default_payment_method);

  return (
    <Stack spacing={theme.spacing(2)}>
      <Card>
        <CardContent>
          <Stack spacing={theme.spacing(2)}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              mb={theme.spacing(2)}
            >
              <Typography fontSize={18} fontWeight={"bolder"}>
                Credit card on file
              </Typography>
              <UbicoButton
                variant="contained"
                onClick={() => setShowCard(true)}
              >
                {hasCard ? "Update card" : "Add card"}
              </UbicoButton>
            </Stack>
            {hasCard ? (
              <>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography variant="body1">Credit card number</Typography>
                  <Typography variant="body2">
                    **** **** ****{" "}
                    {subscription?.default_payment_method?.card?.last4}
                  </Typography>
                </Stack>
                <Divider flexItem />
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography variant="body1">Expiration date</Typography>
                  <Typography variant="body2">
                    {subscription?.default_payment_method?.card?.exp_month}/
                    {subscription?.default_payment_method?.card?.exp_year}
                  </Typography>
                </Stack>
                <Divider flexItem />
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography variant="body1">Postal code</Typography>
                  <Typography variant="body2">
                    {
                      subscription?.default_payment_method?.billing_details
                        ?.address?.postal_code
                    }
                  </Typography>
                </Stack>
              </>
            ) : (
              <Stack
                direction={"row"}
                width={"100%"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography color={"text.secondary"} fontStyle={"italic"}>
                  No card on file
                </Typography>
              </Stack>
            )}
          </Stack>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Stack spacing={theme.spacing(2)}>
            <Typography fontSize={18} fontWeight={"bolder"}>
              Billing details
            </Typography>
            <AddressFormWrapper onUpdateCompleted={onUpdateComplete} />
          </Stack>
        </CardContent>
      </Card>
      <CardUpdateDialog close={() => setShowCard(false)} open={showCard} />
    </Stack>
  );
};

export default BillingDetails;
