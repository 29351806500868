import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Operator } from "../../pages/segments/constants/data-driven/data-types";

const initialState: Operator[] = [];

export const operatorsSlice = createSlice({
  name: "attributes",
  initialState,
  reducers: {
    setOperators: (state, action: PayloadAction<Operator[]>) => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const { setOperators } = operatorsSlice.actions;

export default operatorsSlice.reducer;
