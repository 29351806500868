import {
  Box,
  FormControlLabel,
  Radio,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FC, ReactElement } from "react";
import { useAccountBilling } from "../../../../../../../hooks/useAccountBilling";
import CardFormWrapper from "./CardFormWrapper";

const AddNewCard: FC = (): ReactElement => {
  const theme = useTheme();
  const { billing, setSelectedNewCard, selectedNewCard } = useAccountBilling();

  const updateCardSelect = () => {
    setSelectedNewCard(true);
  };

  return (
    <Stack justifyContent={"center"}>
      {billing?.payment_method && (
        <Stack justifyContent={"center"}>
          <Box pl={theme.spacing(2)}>
            <FormControlLabel
              onChange={updateCardSelect}
              checked={selectedNewCard}
              control={
                <Radio
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 30 },
                    cursor: "inherit",
                  }}
                />
              }
              label={
                <Stack
                  direction={"row"}
                  spacing={theme.spacing(2)}
                  alignItems={"center"}
                >
                  <Typography variant="subtitle1" fontWeight={"bolder"}>
                    Add new card
                  </Typography>
                </Stack>
              }
              labelPlacement="end"
            />
          </Box>
        </Stack>
      )}
      {(selectedNewCard || !billing?.payment_method) && <CardFormWrapper />}
    </Stack>
  );
};

export default AddNewCard;
