import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Notification } from "../../constants/data-types";

const initialState: Notification[] = [];

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<Notification[]>) => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const { setNotifications } = notificationsSlice.actions;

export default notificationsSlice.reducer;
