import axios from "axios";
import { AXIOS_CONFIG, AxiosService } from "../api-provider";
import { PasswordChange } from "../constants/data-types";
import { AUTH_PATH } from "../constants/paths";
import { GENERIC_ERROR_MESSAGE } from "../constants/snackbar-messages";

const LOGIN_ERROR_MAPPING = {
  "User is inactive":
    "Your account trial has expired or your subscription was cancelled. Please contact us to continue using our services.",
  "Account is inactive":
    "Your account trial has expired or your subscription was cancelled. Please contact us to continue using our services.",
};

const autoRefreshToken = async () => {
  // This function will automatically refresh the access token
  // by using the refresh token set in the user's cookies.
  try {
    // Here we use a separate axios instance to avoid having interceptions from the
    // AxiosService (only for refresh token)
    const response = await axios.post(
      `${AUTH_PATH}/token/refresh/`,
      {},
      AXIOS_CONFIG,
    );
    return response;
  } catch (error: any) {
    return {
      error: true,
      message: error.message || GENERIC_ERROR_MESSAGE,
      status: error.status,
    };
  }
};

const refreshToken = async (refresh_token: string) => {
  // This
  try {
    const response = await axios.post(
      `${AUTH_PATH}/token/refresh/`,
      { refresh: refresh_token },
      AXIOS_CONFIG,
    );
    return response.data;
  } catch (error: any) {
    return {
      error: true,
      message: error.message || GENERIC_ERROR_MESSAGE,
      status: error.status,
    };
  }
};

const signup = async (
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  password1: string,
  password2: string,
) => {
  try {
    const response = await AxiosService.post(`${AUTH_PATH}/register/`, {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      password1: password1,
      password2: password2,
    });
    return response.data;
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.email?.join(", ") ||
        error.response?.data?.phone?.join(", ") ||
        error.response?.data?.non_field_errors?.join(", ") ||
        error.response?.data?.password1?.join(", ") ||
        error.response?.data?.password2?.join(", ") ||
        error.response?.data?.detail ||
        error.message ||
        GENERIC_ERROR_MESSAGE,
    };
  }
};

const login = async (email: string, password: string) => {
  try {
    const response = await AxiosService.post(`${AUTH_PATH}/login/`, {
      email: email,
      password: password,
    });
    return response.data;
  } catch (error: any) {
    const errorDetail = error.response?.data?.detail;
    return {
      error: true,
      message:
        error.response?.data?.non_field_errors?.join(", ") ||
        (errorDetail in LOGIN_ERROR_MAPPING
          ? LOGIN_ERROR_MAPPING[errorDetail]
          : errorDetail) ||
        error.message ||
        GENERIC_ERROR_MESSAGE,
    };
  }
};

const googleLogin = async (access_token: string) => {
  try {
    const response = await AxiosService.post(`${AUTH_PATH}/google/`, {
      access_token: access_token,
    });
    return response.data;
  } catch (error: any) {
    const errorDetail = error.response?.data?.detail;
    return {
      error: true,
      message:
        error.response?.data?.non_field_errors?.join(", ") ||
        (errorDetail in LOGIN_ERROR_MAPPING
          ? LOGIN_ERROR_MAPPING[errorDetail]
          : errorDetail) ||
        error.message ||
        GENERIC_ERROR_MESSAGE,
    };
  }
};

const logout = async () => {
  try {
    const response = await AxiosService.post(`${AUTH_PATH}/logout/`, {});
    return response.data;
  } catch (error: any) {
    return {
      error: true,
      message: error.response?.data || error.message,
    };
  }
};

const sendEmailVerification = async (email: string) => {
  try {
    const response = await AxiosService.post(
      `${AUTH_PATH}/registration/resend-email/`,
      { email: email },
    );
    return response.data;
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.email?.join(", ") ||
        error.message ||
        GENERIC_ERROR_MESSAGE,
    };
  }
};

const sendPasswordReset = async (email: string) => {
  try {
    const response = await AxiosService.post(`${AUTH_PATH}/password-reset/`, {
      email: email,
    });
    return response.data;
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.email?.join(", ") ||
        error.message ||
        GENERIC_ERROR_MESSAGE,
    };
  }
};

const changePassword = async (data: PasswordChange) => {
  try {
    const response = await AxiosService.post(
      `${AUTH_PATH}/password-change/`,
      data,
    );
    return response.data;
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.non_field_errors?.join(", ") ||
        error.response?.data?.new_password1?.join(", ") ||
        error.response?.data?.new_password2?.join(", ") ||
        error.response?.data?.old_password?.join(", ") ||
        error.message ||
        GENERIC_ERROR_MESSAGE,
    };
  }
};

const sendPasswordResetConfirm = async (
  uid: string,
  token: string,
  password1: string,
  password2: string,
) => {
  try {
    const response = await AxiosService.post(
      `${AUTH_PATH}/password-reset-confirm/${uid}/${token}/`,
      {
        new_password1: password1,
        new_password2: password2,
        uid: uid,
        token: token,
      },
    );
    return response.data;
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.token?.join(", ") ||
        error.message ||
        GENERIC_ERROR_MESSAGE,
    };
  }
};

const AuthService = {
  autoRefreshToken,
  refreshToken,
  login,
  googleLogin,
  signup,
  logout,
  sendEmailVerification,
  sendPasswordReset,
  sendPasswordResetConfirm,
  changePassword,
};

export default AuthService;
