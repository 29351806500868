import { createContext, useContext, useEffect, useState } from "react";
import {
  CancellationFlowFeedbacks,
  CancellationFlowSteps,
} from "../pages/settings/account/constants/account-billing";

const SubscriptionCancellationContext = createContext<{
  currentStep: CancellationFlowSteps;
  setCurrentStep(step: CancellationFlowSteps);
  feedback: CancellationFlowFeedbacks;
  setFeedback(feedback: CancellationFlowFeedbacks): void;
  comment: string;
  setComment(comment: string): void;
  open: boolean;
  setOpen(open: boolean): void;
}>({} as any);

export const SubscriptionCancellationProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState<CancellationFlowSteps>(
    CancellationFlowSteps.Overview,
  );
  const [feedback, setFeedback] = useState<CancellationFlowFeedbacks>(null);
  const [comment, setComment] = useState("");

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) setCurrentStep(CancellationFlowSteps.Overview);
  }, [open]);

  return (
    <SubscriptionCancellationContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        feedback,
        setFeedback,
        comment,
        setComment,
        open,
        setOpen,
      }}
    >
      {children}
    </SubscriptionCancellationContext.Provider>
  );
};

export const useSubscriptionCancellation = () => {
  return useContext(SubscriptionCancellationContext);
};
