import React, { useEffect, useState } from "react";

import ActionSnackbar from "./ActionSnackbar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { openSnackbar } from "../redux/reducers/snackbarReducer";
import AuthService from "../services/authService";

const HomeNotifications: React.FC = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state: RootState) => state.profile);
  const { completed_account_setup } = useSelector(
    (state: RootState) => state.account,
  );

  const [openEmailConfirmationSnackbar, setOpenEmailConfirmationSnackbar] =
    useState<boolean>(false);

  const sendEmailVerification = async () => {
    try {
      const resp = await AuthService.sendEmailVerification(user.email);
      if (resp.error) {
        dispatch(
          openSnackbar({
            message: resp?.message,
            severity: "error",
            open: true,
          }),
        );
      } else {
        dispatch(
          openSnackbar({
            message: `An email has been sent to ${user.email}`,
            severity: "success",
            open: true,
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        openSnackbar({
          message: "There was an error logging you in.",
          severity: "error",
          open: true,
        }),
      );
    }
  };

  useEffect(() => {
    // Warn user to verify email if needed
    if (user?.verified === false) {
      setOpenEmailConfirmationSnackbar(true);
    }
  }, [user]);

  return (
    <>
      {completed_account_setup && openEmailConfirmationSnackbar && (
        <ActionSnackbar
          message={"Please verify your email."}
          actionText={"Re-send email"}
          onAction={() => sendEmailVerification()}
          severity="info"
        />
      )}
    </>
  );
};

export default HomeNotifications;
