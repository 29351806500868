import { Stripe } from "@stripe/stripe-js";
import { createContext, useContext } from "react";

interface StripePromiseContextProps {
  stripePromise: Stripe;
}

const StripePromiseContext = createContext<StripePromiseContextProps>(
  {} as StripePromiseContextProps,
);

export const StripePromiseProvider = ({ stripePromise, children }) => {
  return (
    <StripePromiseContext.Provider value={{ stripePromise }}>
      {children}
    </StripePromiseContext.Provider>
  );
};

export const useStripePromise = () => {
  return useContext(StripePromiseContext);
};
