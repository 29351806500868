import dayjs from 'dayjs';
import {
  Account,
  UbicoDiscount,
  UbicoInterval,
  UbicoInvoice,
  UbicoPricing,
  UbicoProduct,
  UbicoSubscription,
  UbicoSubscriptionProduct
} from "../../../../constants/data-types";
import {
  BillingRecurrences,
  BillingSubscriptionStatus,
  ProductTypes,
} from "../constants/account-billing";

export const buildStripeProducts = (data: UbicoProduct[]) => {
  // Sort by month recurring price unit
  const sortedSubscriptionProducts = data
    .filter((p) => p.type === ProductTypes.Plan)
    .sort(
      (a, b) =>
        a.prices.find(
          (p) => p.recurrence?.interval === BillingRecurrences.Monthly,
        )?.amount -
        b.prices.find(
          (p) => p.recurrence?.interval === BillingRecurrences.Monthly,
        )?.amount,
    );
  // Sort by month recurring price unit
  const sortedCreditProducts = data
    .filter((p) => p.type === ProductTypes.Credit)
    .sort(
      (a, b) =>
        a.prices.find(
          (p) => p.recurrence?.interval === BillingRecurrences.Monthly,
        )?.amount -
        b.prices.find(
          (p) => p.recurrence?.interval === BillingRecurrences.Monthly,
        )?.amount,
    );
  return {
    subscriptionProducts: sortedSubscriptionProducts,
    creditProducts: sortedCreditProducts,
  };
};

export const formatPricingRecurrence = (recurrence: UbicoInterval) => {
  if (!recurrence) return;
  const { interval, interval_count } = recurrence;
  if (interval_count === 1) {
    switch (interval) {
      case BillingRecurrences.Monthly:
        return "/mo";
      case BillingRecurrences.Yearly:
        return "/yr";
      default:
        return "";
    }
  } else {
    switch (interval) {
      case BillingRecurrences.Monthly:
        return `/${interval_count} mos`;
      case BillingRecurrences.Yearly:
        return `/${interval_count} yrs`;
      default:
        return "";
    }
  }
};

const isSubscriptionPastDueGracePeriod = (subscriptionStartDate: number, account: Account) => {
  /**
   * Validates if the customer is still in the Past Due grace period, before showing the warning.
   */
  const today = new Date().getTime(); // Current date in milliseconds
  const timeDifference = today - subscriptionStartDate * 1000;
  const dayDifference = timeDifference / (1000 * 3600 * 24); // Convert milliseconds to days
  return dayDifference < account.plan_limits?.grace_period;
};

export const isTrialEnded = (subscription: UbicoSubscription, account: Account) => {
  /**
   * Validates if a subscription trial has ended
   */
  const currentDate = new Date();
  const subscriptionTrialEndDate = subscription?.trial_end
    ? new Date(subscription?.trial_end * 1000)
    : null;
  const trialEnded =
    currentDate?.getTime() > subscriptionTrialEndDate?.getTime() &&
    subscription?.status === BillingSubscriptionStatus.Paused &&
    !isSubscriptionPastDueGracePeriod(subscription?.current_period_start, account);
  return trialEnded;
};

export const isCancelled = (subscription: UbicoSubscription, account: Account) => {
  /**
   * Validates if a subscription is canceled
   */
  const subscriptionCanceled =
    subscription?.status === BillingSubscriptionStatus.Canceled &&
    !isSubscriptionPastDueGracePeriod(subscription?.current_period_start, account);
  return subscriptionCanceled;
};

export const isPaymentDue = (subscription: UbicoSubscription, account: Account) => {
  /**
   * Validates if a subscription payment is past due
   */
  const paymentPastDue =
    (subscription?.status === BillingSubscriptionStatus.PastDue ||
      subscription?.status === BillingSubscriptionStatus.Unpaid) &&
    !isSubscriptionPastDueGracePeriod(subscription?.current_period_start, account);
  return paymentPastDue;
};

const lineItemsChanged = (
  currentItems: any[],
  previewItems: any[],
): boolean => {
  const currentItemsSet = new Set(
    currentItems?.map((item) => `${item.product}:${item.quantity}`),
  );
  const previewItemsSet = new Set(
    previewItems?.map((item) => `${item.product}:${item.quantity}`),
  );
  if (currentItemsSet.size !== previewItemsSet.size) {
    return true;
  }
  let hasDifferences = false;
  previewItemsSet.forEach((item) => {
    if (!currentItemsSet.has(item)) {
      hasDifferences = true;
    }
  });

  return hasDifferences;
};

export const subscriptionHasChanged = (
  subscription: UbicoSubscription,
  invoice: UbicoInvoice,
) => {
  /**
   * This function tells if a subscription has changes that should be validated and submitted to
   * Stripe. The following conditions needs to be true in order to evaluate a changed subscription:
   *
   * - Subscription is not Active (Meaning a payment failed, subscription is in trial, past due,
   *   or any other state that requires a subscription update to make it active again)
   * - If the subscription is Active, it should have changes in subscription items, meaning that
   *   there is an update in quantity or products.
   */
  const currentLineItems = subscription?.products.map((item) => ({
    product: item.product_id,
    quantity: item.quantity,
  }));

  const previewLineItems = invoice?.lines.map((item) => ({
    product: item.product_id,
    quantity: item.quantity,
  }));

  // Compare the line items
  return (
    subscription?.status !== BillingSubscriptionStatus.Active ||
    lineItemsChanged(currentLineItems, previewLineItems)
  );
};

export const getSubscriptionPeriodMonths = (subscription: UbicoSubscription) => {
  let totalMonths = 1
  try {
    const currentPeriodStart = dayjs(subscription?.current_period_start * 1000)
    const currentPeriodEnd = dayjs(subscription?.current_period_end * 1000)
    totalMonths = Math.round(currentPeriodEnd.diff(currentPeriodStart, 'months', true))
  } catch {
    return 1
  }

  return totalMonths || 1;
}

export const getDiscountedValue = (amount: number, discount: UbicoDiscount) => {
  if(discount?.percent_off) {
    return (amount * (Number(discount?.percent_off) / 100)).toFixed(2)
  } else if (discount?.amount_off) {
    return (Number(discount?.amount_off || 0)).toFixed(2)
  } else {
    return (amount || 0).toFixed(2)
  }
}

export const getProductCost = (product: UbicoSubscriptionProduct, price: UbicoPricing, quantity: number) => {
  const discounts = product?.discounts
  let cost = price?.amount * quantity
  discounts?.map((d) => {
    cost -= Number(getDiscountedValue(cost, d))
  })
  return cost
}
