import { Stack, useTheme } from "@mui/material";
import { FC, ReactElement } from "react";
import { useSelector } from "react-redux";
import UbicoLinearProgress from "../../../../../../../components/custom/progress.tsx/UbicoLinearProgress";
import {
  Account,
  UbicoSubscription,
} from "../../../../../../../constants/data-types";
import { RootState } from "../../../../../../../redux/store";

const CreditPeriodTotalUsage: FC = (): ReactElement => {
  const theme = useTheme();

  const account: Account = useSelector((state: RootState) => state.account);
  const subscription: UbicoSubscription = useSelector(
    (state: RootState) => state.billing.subscription,
  );

  const planCredits = account?.plan_limits?.period_credits
  const planCreditsUsed = subscription?.credits_used;

  const planCreditsPercentage =
    Math.min((planCreditsUsed / planCredits) * 100, 100) || 0;

  return (
    <Stack spacing={theme.spacing(2)}>
      <UbicoLinearProgress
        sx={{ height: 20, borderRadius: 10 }}
        variant="determinate"
        color={"primary"}
        value={planCreditsPercentage}
        startLabel={"Plan Credits Used"}
        endLabel={`${planCreditsUsed || 0} / ${planCredits}`}
        labelsPosition="bottom"
      />
    </Stack>
  );
};

export default CreditPeriodTotalUsage;
