import {
  Login,
  PasswordReset,
  PasswordResetConfirm,
  Signup,
} from "../loadables";
import { Route } from "./routeInterface";

export const authRoutes: Array<Route> = [
  {
    key: "login-route",
    label: "Login",
    path: "login",
    enabled: true,
    component: Login,
  },
  {
    key: "signup-route",
    label: "Signup",
    path: "signup",
    enabled: true,
    component: Signup,
  },
  {
    key: "password-reset-route",
    label: "Password Reset",
    path: "password-reset",
    enabled: true,
    component: PasswordReset,
  },
  {
    key: "password-reset-confirm-route",
    label: "Password Reset Confirm",
    path: "password-reset-confirm/:uid/:token",
    enabled: true,
    component: PasswordResetConfirm,
  },
];
