import { Stack, SvgIcon, useTheme } from "@mui/material";
import { FC, ReactElement } from "react";
import { ReactComponent as UbicoLogo } from "../../assets/logos/ubico.svg";
import "./ubico-logo-animation.scss";

const UbicoLoading: FC = (): ReactElement => {
  const theme = useTheme();

  return (
    <Stack
      spacing={theme.spacing(2)}
      display={"flex"}
      minWidth={"100vw"}
      minHeight={"100vh"}
      justifyContent={"center"}
      alignItems={"center"}
      className="logo-container"
    >
      <SvgIcon
        className="logo"
        component={UbicoLogo}
        sx={{ color: theme.palette.text.primary, height: 150, width: 150 }}
        inheritViewBox
      />
    </Stack>
  );
};

export default UbicoLoading;
