import { Check, Close } from "@mui/icons-material";
import {
  ButtonProps,
  CircularProgress,
  IconButton,
  Tooltip,
  lighten,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const SAVE_DELAY_MS = 4000;

interface UbicoDelayedButtonProps extends ButtonProps {
  onCancel?: any;
  onDone: any;
  tooltipTitle: string;
}

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "loading",
})<{ loading: boolean }>(({ theme, loading }) => ({
  backgroundColor: loading
    ? theme.palette.background.paper
    : theme.palette.primary.main,
  color: loading
    ? theme.palette.primary.main
    : theme.palette.primary.contrastText,
  "&:hover": {
    backgroundColor: loading
      ? theme.palette.background.paper
      : lighten(theme.palette.primary.main, 0.3),
    color: loading
      ? theme.palette.primary.main
      : lighten(theme.palette.primary.contrastText, 0.3),
  },
}));

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.main,
  position: "absolute",
  top: -1,
  left: -1,
  zIndex: 1,
}));

const UbicoDelayedConfirmButton: React.FC<UbicoDelayedButtonProps> = ({
  tooltipTitle,
  onCancel,
  onDone,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  const onClick = () => {
    if (loading) {
      if (onCancel) onCancel();
      setLoadingPercentage(0);
      setLoading(false);
    } else {
      setLoading(true);
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (loading) {
      let percentage = 0;
      timer = setInterval(() => {
        percentage += 1;
        if (percentage > 100) {
          setLoading(false);
          setLoadingPercentage(0);
          onDone();
        } else {
          setLoadingPercentage(percentage + 20); // We add 20 because of MUI effect is too slow
        }
      }, SAVE_DELAY_MS / 100);
    }
    return () => clearInterval(timer);
  }, [loading]);

  return (
    <Tooltip title={loading ? "Cancel" : tooltipTitle}>
      <StyledIconButton loading={loading} onClick={onClick} {...props}>
        {loading ? <Close /> : <Check />}
        {loading && (
          <StyledCircularProgress
            size={42}
            variant="determinate"
            value={loadingPercentage}
          />
        )}
      </StyledIconButton>
    </Tooltip>
  );
};

export default UbicoDelayedConfirmButton;
