import { Chip, Fade, useTheme } from "@mui/material";
import { FC, ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import NotificationService from "../../services/notificationService";

const NotificationsCountChip: FC = (): ReactElement => {
  const theme = useTheme();

  const notifications = useSelector((state: RootState) => state.notifications);

  const [notificationCount, setNotificationCount] = useState(0);

  const getNotificationCount = async () => {
    const { data, error } =
      await NotificationService.getNotificationsUnreadCount();
    if (!error) setNotificationCount(data);
  };

  useEffect(() => {
    getNotificationCount();
  }, [notifications]);

  return (
    <Fade in={notificationCount > 0}>
      <Chip
        sx={{
          bgcolor: theme.palette.primary.main,
          borderRadius: 2,
          color: theme.palette.primary.contrastText,
          height: 17,
          minWidth: 20,
          "& .MuiChip-label": {
            fontWeight: "bolder",
            fontSize: 12,
            padding: theme.spacing(0.5),
          },
        }}
        size="small"
        label={notificationCount > 99 ? "99+" : notificationCount}
      />
    </Fade>
  );
};

export default NotificationsCountChip;
