import { Button, ButtonProps, Tooltip, styled } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { UserRoles } from "../../../constants/user-roles";
import { RootState } from "../../../redux/store";
import { isUserAllowed } from "../../../utils/user-role-utils";
import UpgradeDialog from "../../billing/UpgradeDialog";

interface UbicoButtonProps extends ButtonProps {
  children: React.ReactNode;
  leastRole?: UserRoles;
  requiresUpgrade?: boolean;
}

const StyledButton = styled(Button)(() => ({
  "&.Mui-disabled": {
    pointerEvents: "auto",
  },
}));

const UbicoButton: React.FC<UbicoButtonProps> = ({
  children,
  requiresUpgrade = false,
  leastRole = null,
  ...props
}) => {
  const { user_role } = useSelector((state: RootState) => state.profile);
  const [showUpgrade, setShowUpgrade] = useState(false);

  let permissionDenied = false;

  if (leastRole) {
    permissionDenied = !isUserAllowed(user_role, leastRole);
  }

  const onButtonClick = (event) => {
    if (requiresUpgrade) {
      setShowUpgrade(true);
    } else if (props?.onClick) {
      props?.onClick(event);
    }
  };

  const disabled = permissionDenied || props.disabled;
  const adjustedButtonProps = {
    disabled: disabled,
    component: disabled ? "div" : undefined,
    onClick: disabled ? undefined : onButtonClick,
  };

  return (
    <>
      <Tooltip
        title={
          permissionDenied ? "You do not have the required permission." : ""
        }
      >
        <StyledButton {...props} {...adjustedButtonProps}>
          {children}
        </StyledButton>
      </Tooltip>
      <UpgradeDialog open={showUpgrade} close={() => setShowUpgrade(false)} />
    </>
  );
};

export default UbicoButton;
