import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogProps,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";
import React from "react";

interface UbicoDialogProps extends DialogProps {
  title: string;
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
}

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialogContent-root": {
    minWidth: 300,
    "& .MuiPaper-root": {
      maxWidth: "none",
    },
  },
  "& .MuiDialogActions-root": {
    minWidth: 300,
  },
}));

const UbicoDialog: React.FC<UbicoDialogProps> = ({
  title,
  open,
  handleClose,
  children,
  ...props
}) => {
  const onClose = () => {
    handleClose();
  };

  return (
    <CustomDialog open={open} onClose={onClose} {...props}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {title}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      {children}
    </CustomDialog>
  );
};

export default UbicoDialog;
