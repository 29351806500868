export const ADMIN_ROLE = 0;
export const WORKSPACE_MANAGER_ROLE = 1;
export const NORMAL_USER_ROLE = 2;
export const COLLABORATOR_ROLE = 3;

export enum UserRoles {
  Admin = 0,
  WorkspaceManager = 1,
  Default = 2,
  Collaborator,
}

export const USER_ROLE_LABELS = {
  [UserRoles.Admin]: "Admin",
  [UserRoles.WorkspaceManager]: "Workspace Manager",
  [UserRoles.Default]: "Default",
  [UserRoles.Collaborator]: "Collaborator",
};

export const USER_ROLES = [
  { id: UserRoles.Admin, label: "Admin" },
  { id: UserRoles.WorkspaceManager, label: "Workspace Manager" },
  { id: UserRoles.Default, label: "Default" },
  { id: UserRoles.Collaborator, label: "Collaborator" },
];
