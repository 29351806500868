import { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UbicoBilling } from "../constants/data-types";
import {
  BILLING_OVERVIEW_TABS,
  BillingDetailTabsType,
  BillingStepType,
  SELF_SERVE_BILLING_STEPS,
} from "../pages/settings/account/constants/account-billing";
import { RootState } from "../redux/store";

interface AccountBillingContextProps {
  currentTab: BillingDetailTabsType;
  setCurrentTab(tab: BillingDetailTabsType): void;
  currentStep: BillingStepType;
  setCurrentStep(step: BillingStepType): void;
  billing: UbicoBilling;
  setBilling(billing: UbicoBilling): void;
  selectedNewCard: boolean;
  setSelectedNewCard(selected: boolean): void;
}

const AccountBillingContext = createContext<AccountBillingContextProps>(
  {} as AccountBillingContextProps,
);

export const AccountBillingProvider = ({ children }) => {
  const [currentTab, setCurrentTab] = useState<BillingDetailTabsType>(
    BILLING_OVERVIEW_TABS[0],
  );
  const [currentStep, setCurrentStep] = useState<BillingStepType>(
    SELF_SERVE_BILLING_STEPS[0],
  );
  const [billing, setBilling] = useState<UbicoBilling>({ plan_quantity: 1 });
  const [selectedNewCard, setSelectedNewCard] = useState(false);

  const subscription = useSelector(
    (state: RootState) => state.billing.subscription,
  );
  const subscriptionProducts = useSelector(
    (state: RootState) => state.billing.subscriptionProducts,
  );
  const creditProducts = useSelector(
    (state: RootState) => state.billing.creditProducts,
  );

  const updateBilling = () => {
    const planProductIds = subscriptionProducts?.map((p) => p?.id);
    const creditProductIds = creditProducts?.map((p) => p?.id);
    const product = subscription?.products?.find((p) =>
      planProductIds?.includes(p.product_id),
    );
    const credit = subscription?.products?.find((p) =>
      creditProductIds?.includes(p.product_id),
    );
    setBilling({
      ...billing,
      plan_product_id: product?.product_id,
      plan_price_id: product?.price_id,
      plan_si_id: product?.si_id,
      credit_product_id: credit?.product_id,
      credit_price_id: credit?.price_id,
      credit_si_id: credit?.si_id,
      recurrence: product?.recurrence,
      payment_method: subscription?.default_payment_method,
      plan_quantity: product?.quantity,
      billing_details: subscription?.billing_details,
    });
  };

  useEffect(() => {
    updateBilling();
  }, [subscription, subscriptionProducts, creditProducts]);

  return (
    <AccountBillingContext.Provider
      value={{
        currentTab,
        setCurrentTab,
        currentStep,
        setCurrentStep,
        billing,
        setBilling,
        selectedNewCard,
        setSelectedNewCard,
      }}
    >
      {children}
    </AccountBillingContext.Provider>
  );
};

export const useAccountBilling = () => {
  return useContext(AccountBillingContext);
};
