import {
  Box,
  Link,
  List,
  ListItemText,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { FallbackProps } from "react-error-boundary";
import { useDispatch } from "react-redux";
import { ReactComponent as UbicoLogo } from "../assets/logos/ubico.svg";
import { UBICO_SUPPORT_URL } from "../constants/urls";
import { setAuth } from "../redux/reducers/authReducer";
import { openSnackbar } from "../redux/reducers/snackbarReducer";
import AuthService from "../services/authService";
import UbicoButton from "./custom/buttons/Button";

const ErrorBoundaryFallback = ({ resetErrorBoundary }: FallbackProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const goToHomePage = () => {
    window.location.replace("/overview");
  };

  const logout = async () => {
    const resp = await AuthService.logout();
    if (resp.error) {
      dispatch(
        openSnackbar({
          message: "There was an error while logging out.",
          open: true,
          severity: "error",
        }),
      );
    }
    dispatch(setAuth({ is_logged_in: false }));
    window.location.replace("/login");
  };

  return (
    <Box
      width={"100%"}
      height={"90vh"}
      display={"flex"}
      justifyContent={"center"}
    >
      <Stack
        maxWidth={"60%"}
        height={"100%"}
        alignItems={"center"}
        direction={"row"}
        spacing={theme.spacing(5)}
      >
        <SvgIcon
          component={UbicoLogo}
          sx={{ color: theme.palette.text.primary, height: 150, width: 150 }}
          inheritViewBox
        />
        <Stack spacing={theme.spacing(2)}>
          <Typography variant="h4">
            We're sorry, but something went wrong.
          </Typography>
          <Typography>
            Ubico is committed to providing you with the best experience, and we
            apologize for any inconvenience this error may have caused.
          </Typography>
          <Typography> What you can do: </Typography>
          <List sx={{ listStyle: "decimal", pl: theme.spacing(3) }}>
            <ListItemText sx={{ display: "list-item" }}>
              Please try{" "}
              <Link onClick={resetErrorBoundary} sx={{ cursor: "pointer" }}>
                refresh
              </Link>{" "}
              the page or using the back button and attempt your action again.
            </ListItemText>
            <ListItemText sx={{ display: "list-item" }}>
              Or try{" "}
              <Link onClick={logout} sx={{ cursor: "pointer" }}>
                logging out
              </Link>{" "}
              and then logging in again or cleaning your browser's cache.
            </ListItemText>
            <ListItemText sx={{ display: "list-item" }}>
              If the issue persists, our technical team has been notified and is
              working diligently to resolve it. You can also contact our support
              team at{" "}
              <Link sx={{ cursor: "pointer" }} href={UBICO_SUPPORT_URL}>
                support@ubico.io
              </Link>{" "}
              for further assistance.
            </ListItemText>
            <ListItemText sx={{ display: "list-item" }}>
              In the meantime, you can return to our{" "}
              <Link sx={{ cursor: "pointer" }} onClick={goToHomePage}>
                home page
              </Link>{" "}
              and explore other parts of our app.
            </ListItemText>
          </List>
          <Typography>
            We appreciate your patience and understanding. Thank you for
            choosing Ubico!
          </Typography>
          <Stack direction={"row"} justifyContent={"flex-start"}>
            <UbicoButton
              size="small"
              variant="contained"
              onClick={resetErrorBoundary}
            >
              Refresh
            </UbicoButton>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ErrorBoundaryFallback;
