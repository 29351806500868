import { createSlice } from "@reduxjs/toolkit";

type DialogState = {
  opened: boolean;
  closeDisabled: boolean;
};

const initialState: DialogState = {
  opened: false,
  closeDisabled: false,
};

export const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    openDialog: (state) => {
      state.opened = true;
    },
    closeDialog: (state) => {
      state.opened = false;
    },
    disableCloseDialog: (state) => {
      state.closeDisabled = false;
    },
  },
});

export const { openDialog, closeDialog, disableCloseDialog } =
  dialogSlice.actions;

export default dialogSlice.reducer;
