import { CircularProgress, Stack, useTheme } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import {
  Appearance,
  StripeElementsOptionsClientSecret,
} from "@stripe/stripe-js";
import { FC, ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  UbicoPaymentIntent,
  UbicoSubscription,
} from "../../../../../../../constants/data-types";
import { useStripePromise } from "../../../../../../../hooks/useStripePromise";
import { RootState } from "../../../../../../../redux/store";
import AccountService from "../../../../../../../services/accountService";
import { THEME_MODE } from "../../../../../../../theme";
import { BillingPaymentIntentStatus } from "../../../../constants/account-billing";
import CardForm from "./CardForm";

interface CardFormWrapperProps {
  onSetupComplete?(): void;
}

const CardFormWrapper: FC<CardFormWrapperProps> = (props): ReactElement => {
  const { onSetupComplete } = props;
  const theme = useTheme();

  const subscription: UbicoSubscription = useSelector(
    (state: RootState) => state.billing.subscription,
  );

  const { stripePromise } = useStripePromise();

  // If Subscription has a pending setup intent, use it for the current payment.
  const [paymentIntent, setPaymentIntent] = useState<UbicoPaymentIntent>(
    subscription?.pending_setup_intent,
  );
  const [isLoadingIntent, setIsLoadingIntent] = useState(false);

  const appearance: Appearance = {
    theme: theme.palette.mode === THEME_MODE.Dark ? "night" : "stripe",
    variables: {
      colorPrimary: theme.palette.primary.main,
      colorBackground: theme.palette.background.default,
      colorSuccess: theme.palette.primary.main,
      colorDanger: theme.palette.error.main,
    },
  };
  const clientSecretOptions: StripeElementsOptionsClientSecret = {
    clientSecret: paymentIntent?.client_secret,
    appearance,
  };

  const getPaymentIntent = async () => {
    setIsLoadingIntent(true);
    const { error, data } = await AccountService.getPaymentIntent();
    if (!error && data) setPaymentIntent(data);
    setIsLoadingIntent(false);
  };

  useEffect(() => {
    if (
      !paymentIntent ||
      paymentIntent?.status !== BillingPaymentIntentStatus.RequiredPaymentMethod
    ) {
      getPaymentIntent();
    }
  }, [paymentIntent]);

  if (isLoadingIntent)
    return (
      <Stack
        width={"100%"}
        direction={"row"}
        minHeight={100}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress />
      </Stack>
    );

  if (
    paymentIntent?.client_secret &&
    paymentIntent?.status === BillingPaymentIntentStatus.RequiredPaymentMethod
  ) {
    return (
      <Elements
        key={paymentIntent?.id}
        stripe={stripePromise}
        options={clientSecretOptions}
      >
        <CardForm
          paymentIntent={paymentIntent}
          onSetupComplete={onSetupComplete}
        />
      </Elements>
    );
  }

  return <></>;
};

export default CardFormWrapper;
