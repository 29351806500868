import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectValue } from "../../constants/selects/data-types";

const initialState: SelectValue[] = [];

export const jobSenioritiesSlice = createSlice({
  name: "job-seniorities",
  initialState,
  reducers: {
    setJobSeniorities: (state, action: PayloadAction<SelectValue[]>) => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const { setJobSeniorities } = jobSenioritiesSlice.actions;

export default jobSenioritiesSlice.reducer;
