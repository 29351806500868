import { LoadingButton } from "@mui/lab";
import { Alert, Stack, Typography, useTheme } from "@mui/material";
import { FC, ReactElement, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UbicoButton from "../../../../../../components/custom/buttons/Button";
import {
  Profile,
  UbicoSubscription,
} from "../../../../../../constants/data-types";
import { useSubscriptionCancellation } from "../../../../../../hooks/useSubscriptionCancellation";
import { setSubscription } from "../../../../../../redux/reducers/billingReducer";
import { RootState } from "../../../../../../redux/store";
import AccountService from "../../../../../../services/accountService";
import {
  CancellationFlowSteps,
  ProductTypes,
} from "../../../constants/account-billing";
import { formatPricingRecurrence } from "../../../utils/billing";

const SubscriptionCancelDeal: FC = (): ReactElement => {
  const { setCurrentStep } = useSubscriptionCancellation();

  const theme = useTheme();
  const dispatch = useDispatch();

  const subscription: UbicoSubscription = useSelector(
    (state: RootState) => state.billing.subscription,
  );
  const profile: Profile = useSelector((state: RootState) => state.profile);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const planProduct = subscription?.products?.find(
    (p) => p.type === ProductTypes.Plan,
  );
  const deal = planProduct?.amount * 0.5;

  const onRefuseDeal = () => {
    setCurrentStep(CancellationFlowSteps.Feedback);
  };

  const onAcceptDeal = async () => {
    setLoading(true);
    const { error, data, message } =
      await AccountService.applySubscriptionCoupon();
    if (!error && data) {
      dispatch(setSubscription(subscription));
      setCurrentStep(CancellationFlowSteps.AcceptedDeal);
      setError("");
    } else setError(message);
    setLoading(false);
  };

  return (
    <Stack spacing={theme.spacing(2)}>
      <Typography variant="h6">
        {profile?.user?.first_name}, we are sad to see you leave 😔
      </Typography>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
      >
        <Stack spacing={theme.spacing(2)} justifyContent={"center"}>
          <Typography>
            Before you go, how about <strong>50% off</strong> your subscription
            for the next 3 months?
          </Typography>
          <Stack
            direction={"row"}
            spacing={theme.spacing(0.5)}
            alignItems={"flex-end"}
          >
            <Typography
              variant="h5"
              color="text.secondary"
              sx={{ textDecoration: "line-through" }}
            >
              {planProduct?.amount ? planProduct?.amount.toString() : 0}$
            </Typography>
            <Typography variant="h5" color={"primary"} fontWeight={"bolder"}>
              {deal?.toString()}$
            </Typography>
            <Typography color={"primary"}>
              {formatPricingRecurrence(planProduct?.recurrence)}
            </Typography>
            <Typography variant="body2" fontWeight={"bolder"} color={"primary"}>
              (for the next 3 months)
            </Typography>
          </Stack>
          {error && (
            <Alert severity="error" variant="outlined">
              {error}
            </Alert>
          )}
        </Stack>
      </Stack>
      <Stack
        direction={"row"}
        spacing={theme.spacing(2)}
        pt={theme.spacing(3)}
        justifyContent={"flex-end"}
      >
        <UbicoButton
          variant="contained"
          sx={{ textTransform: "capitalize" }}
          color="inherit"
          onClick={onRefuseDeal}
        >
          No thank you
        </UbicoButton>
        <LoadingButton
          loading={loading}
          variant="contained"
          sx={{ textTransform: "capitalize" }}
          onClick={onAcceptDeal}
        >
          Yes, sign me up!
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default SubscriptionCancelDeal;
