import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectValue } from "../../constants/selects/data-types";

const initialState: SelectValue[] = [];

export const jobFunctionsSlice = createSlice({
  name: "job-functions",
  initialState,
  reducers: {
    setJobFunctions: (state, action: PayloadAction<SelectValue[]>) => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const { setJobFunctions } = jobFunctionsSlice.actions;

export default jobFunctionsSlice.reducer;
