import { Chip } from "@mui/material";
import { FC, ReactElement } from "react";

const NewFeatureChip: FC = (): ReactElement => {
  return (
    <Chip
      label={"New"}
      color="primary"
      size="small"
      sx={{
        "& .MuiChip-label": {
          fontWeight: "bolder",
          fontSize: 10,
          paddingTop: 0,
          paddingBottom: 0,
        },
        height: 20,
      }}
    />
  );
};

export default NewFeatureChip;
