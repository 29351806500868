import {
  Chip,
  FormControlLabel,
  Radio,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FC, ReactElement } from "react";
import { useSelector } from "react-redux";
import { UbicoSubscription } from "../../../../../../../constants/data-types";
import { useAccountBilling } from "../../../../../../../hooks/useAccountBilling";
import { RootState } from "../../../../../../../redux/store";

const ExistingCard: FC = (): ReactElement => {
  const { billing, setBilling, selectedNewCard, setSelectedNewCard } =
    useAccountBilling();

  const subscription: UbicoSubscription = useSelector(
    (state: RootState) => state.billing.subscription,
  );

  const theme = useTheme();

  const payment =
    billing?.payment_method || subscription?.default_payment_method;

  const updateCardSelected = () => {
    setBilling({ ...billing, payment_method: payment });
    setSelectedNewCard(false);
  };

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      pl={theme.spacing(2)}
      pr={theme.spacing(2)}
      width={"100%"}
    >
      <FormControlLabel
        onChange={updateCardSelected}
        checked={Boolean(billing?.payment_method) && !selectedNewCard}
        control={
          <Radio
            disableFocusRipple
            disableRipple
            disableTouchRipple
            sx={{ "& .MuiSvgIcon-root": { fontSize: 30 }, cursor: "inherit" }}
          />
        }
        label={
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack
              width={"100%"}
              direction={"row"}
              spacing={theme.spacing(2)}
              alignItems={"center"}
            >
              <Typography variant="subtitle1" fontWeight={"bolder"}>
                **** **** **** {payment?.card?.last4}
              </Typography>
              <Chip
                size="small"
                label={
                  <Typography textTransform={"capitalize"} variant="body2">
                    {payment?.card?.brand}
                  </Typography>
                }
              />
            </Stack>
          </Stack>
        }
        labelPlacement="end"
      />
      <Typography>
        Expires {payment?.card?.exp_month}/{payment?.card?.exp_year}
      </Typography>
    </Stack>
  );
};

export default ExistingCard;
