import { Brightness4, Brightness7 } from "@mui/icons-material";
import {
  AppBar,
  IconButton,
  Stack,
  SvgIcon,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ColorModeContext } from "../../../Providers";
import { ReactComponent as UbicoLogo } from "../../../assets/logos/ubico.svg";
import { setColorMode } from "../../../redux/reducers/appReducer";
import { RootState } from "../../../redux/store";
import { THEME_MODE } from "../../../theme";

const NavbarTop: React.FC = (): React.ReactElement => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { toggleAppColorMode } = useContext(ColorModeContext);

  const { is_logged_in } = useSelector((state: RootState) => state.auth);
  const { completed_account_setup } = useSelector(
    (state: RootState) => state.account,
  );

  const toggleColorMode = () => {
    dispatch(setColorMode());
    toggleAppColorMode();
  };

  return !is_logged_in || !completed_account_setup ? (
    <AppBar elevation={0} sx={{ bgcolor: theme.palette.background.paper }}>
      <Toolbar variant="dense">
        <Box display="flex" flexDirection={"column"} justifyContent="center">
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={theme.spacing(2)}
            component={Link}
            to="/"
            sx={{ textDecoration: "none" }}
          >
            <SvgIcon
              component={UbicoLogo}
              sx={{ color: theme.palette.text.primary }}
              inheritViewBox
            />
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Ubuntu",
                color: theme.palette.text.primary,
                boxShadow: "none",
              }}
            >
              ubico
            </Typography>
          </Stack>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Stack display="flex" direction="row" spacing={theme.spacing(1)}>
          <Tooltip
            title={
              theme.palette.mode === THEME_MODE.Light
                ? "Turn off the light"
                : "Turn on the light"
            }
          >
            <IconButton onClick={toggleColorMode} size="small">
              {theme.palette.mode === THEME_MODE.Light ? (
                <Brightness4 fontSize="small" />
              ) : (
                <Brightness7 fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Stack>
      </Toolbar>
    </AppBar>
  ) : (
    <></>
  );
};

export default NavbarTop;
