import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AttributeGroup } from "../../pages/segments/constants/data-driven/data-types";

const initialState: AttributeGroup[] = [];

export const attributesSlice = createSlice({
  name: "attributes",
  initialState,
  reducers: {
    setAttributes: (state, action: PayloadAction<AttributeGroup[]>) => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const { setAttributes } = attributesSlice.actions;

export default attributesSlice.reducer;
