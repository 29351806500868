import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FC, ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import TrialEndImage from "../../assets/images/dazzle-software-testing.png";
import { BILLING_ROUTE } from "../../routes/appRoutes";
import UbicoButton from "../custom/buttons/Button";

interface TrialEndDialogProps {
  open: boolean;
}

const TrialEndDialog: FC<TrialEndDialogProps> = (props): ReactElement => {
  const { open } = props;
  const theme = useTheme();

  const navigate = useNavigate();

  return (
    <Dialog
      open={open}
      sx={{
        backdropFilter: "blur(5px)",
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <DialogTitle>Your trial period has ended</DialogTitle>
      <DialogContent>
        <Stack
          spacing={theme.spacing(2)}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box
            component="img"
            src={TrialEndImage}
            sx={{
              maxHeight: 200,
              maxWidth: 200,
              width: "auto",
              height: "auto",
              display: "block",
            }}
          />
          <Typography>
            If you want to continue using Ubico, you will need to update your
            payment method.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <UbicoButton
          variant="contained"
          onClick={() => navigate(BILLING_ROUTE)}
        >
          Update payment method
        </UbicoButton>
      </DialogActions>
    </Dialog>
  );
};

export default TrialEndDialog;
