import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AuthState = {
  is_logged_in: boolean;
};

const initialState: AuthState = {
  is_logged_in: true,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<AuthState>) => {
      state.is_logged_in = action.payload.is_logged_in;
    },
    logout: (state) => {
      state.is_logged_in = false;
    },
  },
});

export const { setAuth, logout } = authSlice.actions;

export default authSlice.reducer;
