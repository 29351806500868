import React, { useState } from "react";

import { Business, Delete, ManageAccounts } from "@mui/icons-material";
import {
  Avatar,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import UbicoDialog from "../../../../components/custom/Dialog";
import UbicoDelayedConfirmButton from "../../../../components/custom/buttons/DelayedConfirmButton";
import { USER_ROLE_LABELS } from "../../../../constants/user-roles";
import { openSnackbar } from "../../../../redux/reducers/snackbarReducer";
import InviteService from "../../../../services/inviteService";
import ProfileService from "../../../../services/profileService";

interface InvitationsDialogProps {
  invitations: Array<any>;
}
const InvitationsDialog: React.FC<InvitationsDialogProps> = ({
  invitations,
}) => {
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onAcceptInvite = async (invite: any) => {
    const { error, message } = await ProfileService.migrateProfile({
      target_account_id: invite.account_id,
    });
    if (!error) {
      dispatch(
        openSnackbar({
          open: true,
          message: `Successfully migrated to ${invite.account_name}.`,
          severity: "success",
        }),
      );
      navigate(0);
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: message,
          severity: "error",
        }),
      );
    }
    setOpen(false);
  };

  const onDeleteInvite = async (invite: any) => {
    const { error, message } = await InviteService.deleteInvite(invite.id);
    if (!error) {
      dispatch(
        openSnackbar({
          open: true,
          message: message,
          severity: "success",
        }),
      );
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: message,
          severity: "error",
        }),
      );
    }
    setOpen(false);
  };

  return (
    <UbicoDialog
      open={open}
      handleClose={() => setOpen(false)}
      title={"Invitation request"}
    >
      <DialogContent dividers>
        <Grid container flexDirection={"column"} spacing={theme.spacing(2)}>
          <Grid item xs={12}>
            <Typography>
              You have been invited to join a team. If you accept an invitation,
              your profile will be migrated to that company,{" "}
              <strong>leaving all your data behind</strong>.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <List>
              {invitations.map((invitation, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemText>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={theme.spacing(2)}
                      >
                        <Avatar sx={{ width: 30, height: 30 }}>
                          <Business fontSize="small" />
                        </Avatar>
                        <Typography variant="subtitle1">
                          {invitation.account_name}
                        </Typography>
                        <Avatar sx={{ width: 30, height: 30 }}>
                          <ManageAccounts fontSize="small" />
                        </Avatar>
                        <Typography variant="subtitle1">
                          {USER_ROLE_LABELS[invitation.invitee_role]}
                        </Typography>
                      </Stack>
                    </ListItemText>

                    <ListItemSecondaryAction>
                      <Grid item container>
                        <Tooltip title={"Cancel invitation"}>
                          <IconButton
                            onClick={() => onDeleteInvite(invitation)}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                        <UbicoDelayedConfirmButton
                          onDone={() => onAcceptInvite(invitation)}
                          sx={{ ml: theme.spacing(2) }}
                          tooltipTitle={"Accept invitation"}
                        />
                      </Grid>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </DialogContent>
    </UbicoDialog>
  );
};

export default InvitationsDialog;
