import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NylasAccount } from "../../constants/data-types";

const initialState: NylasAccount[] = [];

export const sendersSlice = createSlice({
  name: "senders",
  initialState,
  reducers: {
    setSenders: (state, action: PayloadAction<NylasAccount[]>) => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const { setSenders } = sendersSlice.actions;

export default sendersSlice.reducer;
