import { LocalOffer } from "@mui/icons-material";
import { Chip, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { FC, ReactElement } from "react";

const MultipleCouponChip: FC = (): ReactElement => {
  const theme = useTheme()

  return (
    <Tooltip title="View invoice for more details">
      <Chip
        color="primary" size="small"
        label={
          <Stack
            direction={"row"}
            spacing={theme.spacing(0.5)}
            alignItems={"center"}
          >
            <LocalOffer sx={{ fontSize: 12 }} />
            <Typography variant="caption">
              Discounts applied
            </Typography>
          </Stack>
        }
      />
    </Tooltip>
  );
};

export default MultipleCouponChip;
