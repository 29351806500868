import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Divider,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { PaymentMethod, StripePaymentElementOptions } from "@stripe/stripe-js";
import { FC, ReactElement, useState } from "react";
import { UbicoPaymentIntent } from "../../../../../../../constants/data-types";
import { useAccountBilling } from "../../../../../../../hooks/useAccountBilling";
import {
  BillingSteps,
  SELF_SERVE_BILLING_STEPS,
} from "../../../../constants/account-billing";

const paymentElementOptions: StripePaymentElementOptions = {
  layout: "tabs",
};

interface CardFormProps {
  paymentIntent: UbicoPaymentIntent;
  onSetupComplete?(): void;
}

const CardForm: FC<CardFormProps> = (props): ReactElement => {
  const { paymentIntent, onSetupComplete } = props;

  const theme = useTheme();

  const elements = useElements();
  const stripe = useStripe();

  const { setCurrentStep, setBilling, billing, setSelectedNewCard } =
    useAccountBilling();

  const [isConfirming, setIsConfirming] = useState(false);
  const [error, setError] = useState("");

  const completePayment = async () => {
    setIsConfirming(true);

    const { error: submitErrors } = await elements.submit();
    if (submitErrors) {
      setError(submitErrors.message);
      setIsConfirming(false);
      return;
    }

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      clientSecret: paymentIntent?.client_secret,
      redirect: "if_required",
      confirmParams: {
        expand: ["payment_method"],
      },
    });

    const paymentMethod = setupIntent?.payment_method as PaymentMethod;

    if (error) {
      setError(error.message);
      setIsConfirming(false);
      return;
    }
    setBilling({ ...billing, payment_method: paymentMethod });
    setSelectedNewCard(false);
    onSetupComplete && onSetupComplete();
    setIsConfirming(false);

    const nextStep = SELF_SERVE_BILLING_STEPS.find(
      (s) => s.id === BillingSteps.BillingDetails,
    );
    setCurrentStep(nextStep);
  };

  return (
    <Stack>
      <Stack p={theme.spacing(3)} spacing={theme.spacing(2)}>
        {error && (
          <Alert severity="error">
            <Typography variant="body2">{error}</Typography>
          </Alert>
        )}
        <PaymentElement options={paymentElementOptions} />
      </Stack>
      <Divider flexItem />
      <Stack
        direction={"row"}
        p={theme.spacing(2)}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="body2">
          Payments are processed securely through{" "}
          <Link href="https://stripe.com/en-ca">Stripe</Link>
        </Typography>
        <LoadingButton
          variant="contained"
          loading={isConfirming}
          onClick={completePayment}
        >
          Confirm details
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default CardForm;
