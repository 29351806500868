import { ReactElement } from "react";

export enum TasksTabEnum {
  Productivity = "productivity",
  Draft = "drafts",
}

export enum TasksDetailTabEnum {
  Draft = "draft",
  Notes = "notes",
  Person = "person",
  Company = "company",
  Segments = "segments",
  Campaigns = "campaigns",
  SentEmails = "sent-emails",
}

export interface TemplateTab {
  id: TasksTabEnum;
  label: string;
  content: ReactElement;
}
