import { Divider, Radio, Stack, Typography, useTheme } from "@mui/material";
import { FC, ReactElement } from "react";
import { useSelector } from "react-redux";
import {
  UbicoPricing,
  UbicoProduct,
  UbicoSubscription,
} from "../../../../../../../constants/data-types";
import { useAccountBilling } from "../../../../../../../hooks/useAccountBilling";
import { RootState } from "../../../../../../../redux/store";
import { BillingRecurrenceLabels, Currencies, ProductTypes } from "../../../../constants/account-billing";
import { formatPricingRecurrence, getDiscountedValue } from "../../../../utils/billing";

const ContractTerms: FC = (): ReactElement => {
  const theme = useTheme();

  const { setBilling, billing } = useAccountBilling();

  const subscription: UbicoSubscription = useSelector(
    (state: RootState) => state.billing.subscription,
  );
  const subscriptionProducts: UbicoProduct[] = useSelector(
    (state: RootState) => state.billing.subscriptionProducts,
  );
  const creditProducts: UbicoProduct[] = useSelector(
    (state: RootState) => state.billing.creditProducts,
  );

  const changePricing = (p: UbicoPricing) => {
    const creditProduct = creditProducts?.find(
      (p) => p.id === billing?.credit_product_id,
    );
    const creditPrice = creditProduct?.prices?.find(
      (pp) =>
        pp?.recurrence?.interval === p?.recurrence?.interval &&
        pp?.recurrence?.interval_count === p?.recurrence?.interval_count,
    );
    setBilling({
      ...billing,
      plan_price_id: p.id,
      credit_price_id: creditPrice?.id,
      recurrence: p.recurrence,
    });
  };

  const subscriptionProduct = subscriptionProducts?.find(
    (p) => p.id === billing?.plan_product_id,
  );
  const sortedPrices = subscriptionProduct?.prices
    ?.filter(p => p.currency === Currencies.USD)
    ?.slice()
    ?.sort((a, b) => a.amount - b.amount);

  const planProduct = subscription?.products?.find(
    (p) => p.type === ProductTypes.Plan,
  );
  const discounts = planProduct?.discounts

  return (
    <Stack>
      <Divider flexItem />
      {sortedPrices?.map((p, index) => {
        let subscriptionCost = p?.amount * billing?.plan_quantity
        discounts?.map((d) => {
          subscriptionCost -= Number(getDiscountedValue(subscriptionCost, d))
        })

        const isSelected =
          billing?.recurrence?.interval === p?.recurrence?.interval;
        const creditProduct = creditProducts?.find(
          (p) => p.id === billing?.credit_product_id,
        );
        const creditCost = creditProduct?.prices?.find(
          (pp) =>
            p?.recurrence?.interval === pp?.recurrence?.interval &&
            p?.recurrence?.interval_count === pp?.recurrence?.interval_count,
        );

        const totalCost = (subscriptionCost || 0) + (creditCost?.amount || 0);
        return (
          <Stack key={index}>
            <Divider flexItem />
            <Stack
              spacing={theme.spacing(1)}
              padding={theme.spacing(2)}
              bgcolor={isSelected && theme.palette.action.selected}
              onClick={() => changePricing(p)}
            >
              <Stack
                alignItems={"center"}
                direction={"row"}
                spacing={theme.spacing(1)}
              >
                <Radio
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 30 },
                    padding: 0,
                    cursor: "inherit",
                  }}
                  checked={isSelected}
                />
                <Stack
                  alignItems={"center"}
                  width={"100%"}
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Stack spacing={theme.spacing(1)}>
                    <Typography
                      variant="h6"
                      fontWeight={"bolder"}
                      textTransform={"capitalize"}
                    >
                      {BillingRecurrenceLabels[p?.recurrence?.interval]}
                    </Typography>
                  </Stack>
                  <Stack alignItems={"center"}>
                    <Stack direction={"row"} spacing={theme.spacing(0.5)}>
                      <Typography color={"primary"}>$</Typography>
                      <Typography
                        color={"primary"}
                        display={"inline"}
                        fontWeight={"bolder"}
                        variant="h5"
                      >
                        {totalCost}
                      </Typography>
                      <Typography
                        color={"primary"}
                        alignSelf={"flex-end"}
                        display={"inline"}
                      >
                        {formatPricingRecurrence(p?.recurrence)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Typography
                variant="body2"
                pl={theme.spacing(5)}
                color={"text.secondary"}
              >
                {p?.nickname}
              </Typography>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default ContractTerms;
