import { LoadingButton } from "@mui/lab";
import {
  CircularProgress,
  Divider,
  Stack,
  Typography,
  useTheme
} from "@mui/material";
import { FC, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  UbicoInvoice,
  UbicoProduct,
  UbicoSubscription,
} from "../../../../../../../constants/data-types";
import { GENERIC_ERROR_MESSAGE } from "../../../../../../../constants/snackbar-messages";
import { useAccountBilling } from "../../../../../../../hooks/useAccountBilling";
import { setSubscription } from "../../../../../../../redux/reducers/billingReducer";
import { openSnackbar } from "../../../../../../../redux/reducers/snackbarReducer";
import { RootState } from "../../../../../../../redux/store";
import AccountService from "../../../../../../../services/accountService";
import { BillingSteps, BillingSubscriptionStatus } from "../../../../constants/account-billing";
import { subscriptionHasChanged } from "../../../../utils/billing";
import InvoiceDetails from "../invoice/InvoiceDetails";

const Review: FC = (): ReactElement => {
  const { billing, currentStep } = useAccountBilling();

  const subscription: UbicoSubscription = useSelector(
    (state: RootState) => state.billing.subscription,
  );
  const subscriptionProducts: UbicoProduct[] = useSelector(
    (state: RootState) => state.billing.subscriptionProducts,
  );

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isCompleting, setIsCompleting] = useState(false);
  const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);
  const [upcomingInvoice, setUpcomingInvoice] = useState<UbicoInvoice>(null);

  const plan = subscriptionProducts.find(
    (p) => p.id === billing?.plan_product_id,
  );

  const getInvoicePreview = async () => {
    setIsLoadingInvoice(true);
    const { error, data } = await AccountService.getPreviewInvoice(
      billing?.plan_si_id,
      billing?.plan_price_id,
      billing?.credit_si_id,
      billing?.credit_price_id,
      billing?.plan_quantity,
    );
    if (!error && data) setUpcomingInvoice(data);
    setIsLoadingInvoice(false);
  };

  const completePayment = async () => {
    setIsCompleting(true);
    const { error, data, message } =
      await AccountService.updateSubscription(billing);
    if (!error && data) {
      dispatch(setSubscription(data));
      dispatch(
        openSnackbar({
          open: true,
          severity: "success",
          message:
            "Successfully updated your subscription! Modifications will be applied shortly.",
        }),
      );
      navigate("../billing");
    } else {
      dispatch(
        openSnackbar({
          open: true,
          severity: "error",
          message: message || GENERIC_ERROR_MESSAGE,
        }),
      );
    }
    setIsCompleting(false);
  };

  useEffect(() => {
    if (currentStep?.id === BillingSteps.Review && billing) getInvoicePreview();
  }, [currentStep]);

  const startDate = new Date(
    upcomingInvoice?.period_start * 1000,
  ).toDateString();
  const endDate = new Date(upcomingInvoice?.period_end * 1000).toDateString();

  if (isLoadingInvoice)
    return (
      <Stack height={100} justifyContent={"center"} alignItems={"center"}>
        <CircularProgress />
      </Stack>
    );

  const changesDetected = subscriptionHasChanged(subscription, upcomingInvoice);

  return (
    <Stack>
      {!changesDetected ? (
        <Stack
          width={"100%"}
          minHeight={200}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography>No changes in your subscription.</Typography>
        </Stack>
      ) : (
        <Stack>
          <Stack>
            <Stack pl={theme.spacing(2)} pt={theme.spacing(2)}>
              <Typography fontWeight={"bolder"} variant="subtitle1">
                Effective changes as of today
              </Typography>
              <Typography variant="caption" color={"text.secondary"}>
                These changes will apply immediately, and will be charged the
                pro-rated amount today.
              </Typography>
            </Stack>
            <Stack p={theme.spacing(2)}>
              <Typography>Subscription to {plan?.name} plan</Typography>

              {upcomingInvoice?.period_start && upcomingInvoice?.period_end && (
                <Stack direction={"row"} justifyContent={"space-between"}>
                  {subscription?.status === BillingSubscriptionStatus.Paused ?
                    <Typography variant="body2">Your subscription billing cycle will start <strong>today</strong></Typography>
                    :
                    <Typography variant="body2">
                      From <strong>{startDate}</strong> to{" "}
                      <strong>{endDate}</strong>
                    </Typography>
                  }
                </Stack>
              )}
            </Stack>
            <InvoiceDetails invoice={upcomingInvoice}/>
          </Stack>
        </Stack>
      )}
      <Divider />
      <Stack
        p={theme.spacing(2)}
        direction={"row"}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        <LoadingButton
          variant="contained"
          loading={isCompleting}
          onClick={completePayment}
          disabled={!changesDetected}
        >
          Confirm payment
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default Review;
