import React, { useState } from "react";

import { Alert, Button, Snackbar, useTheme } from "@mui/material";
import { SNACKBAR_DEFAULT_DURATION } from "../redux/reducers/snackbarReducer";

interface ActionSnackbarProps {
  message: string;
  severity: "error" | "warning" | "info" | "success";
  actionText: string;
  onAction: any;
}

const ActionSnackbar: React.FC<ActionSnackbarProps> = (props) => {
  const { message, severity, actionText, onAction } = props;
  const theme = useTheme();

  const [open, setOpen] = useState<boolean>(true);

  return (
    <Snackbar
      open={open}
      autoHideDuration={SNACKBAR_DEFAULT_DURATION}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Alert
        onClose={() => setOpen(false)}
        severity={severity}
        variant="filled"
        sx={{ color: theme.palette.common.white }}
        action={
          actionText &&
          onAction && (
            <Button
              color="inherit"
              size="small"
              onClick={() => onAction() && setOpen(false)}
            >
              {actionText}
            </Button>
          )
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ActionSnackbar;
