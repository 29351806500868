import {
  Box,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { FC, ReactElement } from "react";
import UpgradeImage from "../../assets/images/dizzy-man-with-a-laptop-looking-at-a-graph-of-investment-growth.gif";
import UbicoButton from "../custom/buttons/Button";
import UbicoDialog from "../custom/Dialog";

interface UpgradeDialogProps {
  open: boolean;
  close(): void;
}

const UpgradeDialog: FC<UpgradeDialogProps> = (props): ReactElement => {
  const { open, close } = props;

  return (
    <UbicoDialog
      open={open}
      handleClose={close}
      title="You have reached your limit!"
      sx={{
        backdropFilter: "blur(5px)",
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <DialogContent>
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Box
            component="img"
            src={UpgradeImage}
            sx={{
              maxHeight: 250,
              maxWidth: 250,
              width: "auto",
              height: "auto",
              display: "block",
            }}
          />
          <Typography>
            In order to keep the momentum going, please upgrade your
            subscription plan.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <UbicoButton variant="contained" href="mailto:sales@ubico.io">
          Upgrade plan
        </UbicoButton>
      </DialogActions>
    </UbicoDialog>
  );
};

export default UpgradeDialog;
