import { Divider, Radio, Stack, Typography, useTheme } from "@mui/material";
import { FC, ReactElement } from "react";
import { useSelector } from "react-redux";
import {
  UbicoPricing,
  UbicoProduct,
} from "../../../../../../../constants/data-types";
import { useAccountBilling } from "../../../../../../../hooks/useAccountBilling";
import { RootState } from "../../../../../../../redux/store";
import { formatPricingRecurrence } from "../../../../utils/billing";

const Credits: FC = (): ReactElement => {
  const theme = useTheme();

  const { billing, setBilling } = useAccountBilling();

  const creditProducts: UbicoProduct[] = useSelector(
    (state: RootState) => state.billing.creditProducts,
  );

  const changePlan = (product: UbicoProduct, p: UbicoPricing) => {
    setBilling({
      ...billing,
      credit_product_id: product?.id,
      credit_price_id: p?.id,
    });
  };

  const noPlanSelected = !billing?.credit_product_id;

  return (
    <Stack>
      <Divider flexItem />
      <Stack
        onClick={() => changePlan(null, null)}
        direction={"row"}
        alignItems={"center"}
        padding={theme.spacing(2)}
        spacing={theme.spacing(1)}
      >
        <Radio
          disableFocusRipple
          disableRipple
          disableTouchRipple
          sx={{
            "& .MuiSvgIcon-root": { fontSize: 30 },
            padding: 0,
            cursor: "inherit",
          }}
          checked={noPlanSelected}
        />
        <Typography variant="body1">No extra credits</Typography>
      </Stack>
      {creditProducts?.map((p) => {
        const price = p.prices.find(
          (pp) =>
            pp.recurrence?.interval === billing?.recurrence?.interval &&
            pp.recurrence?.interval_count ===
              billing?.recurrence?.interval_count,
        );
        const isSelected = billing?.credit_price_id === price?.id;
        return (
          <Stack key={p.id}>
            <Divider flexItem />
            <Stack
              alignItems={"flex-start"}
              direction={"row"}
              spacing={theme.spacing(1)}
              padding={theme.spacing(2)}
              bgcolor={isSelected && theme.palette.action.selected}
              onClick={() => changePlan(p, price)}
            >
              <Radio
                disableFocusRipple
                disableRipple
                disableTouchRipple
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: 30 },
                  padding: 0,
                  cursor: "inherit",
                }}
                checked={isSelected}
              />
              <Stack
                width={"100%"}
                direction={"row"}
                justifyContent={"space-between"}
              >
                <Stack spacing={theme.spacing(1)}>
                  <Typography variant="h6" fontWeight={"bolder"}>
                    {p.name}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={theme.spacing(0.5)}>
                  <Typography color={"primary"}>$</Typography>
                  <Typography
                    color={"primary"}
                    display={"inline"}
                    fontWeight={"bolder"}
                    variant="h5"
                  >
                    {price?.amount}
                  </Typography>
                  <Typography
                    color={"primary"}
                    fontSize={14}
                    alignSelf={"flex-end"}
                    display={"inline"}
                  >
                    {formatPricingRecurrence(price?.recurrence)}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default Credits;
