import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Timezone } from "../../pages/campaigns/constants/data-types";

const initialState: Timezone[] = [];

export const timezonesSlice = createSlice({
  name: "timezones",
  initialState,
  reducers: {
    setTimezones: (state, action: PayloadAction<Timezone[]>) => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const { setTimezones } = timezonesSlice.actions;

export default timezonesSlice.reducer;
